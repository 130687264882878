import React from "react";
import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import websling from "../assets/websling.jpg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import webSling from "./../assets/websling2.jpg";
import sld from "./../assets/sld-logo.svg";
import Erigging from "./../assets/erigging.png";
import EyeTypes from './EyeTypes'
import kam from "./../assets/kam-bg-3.png";
import PlyEyeXEyeTaperedThumb from '../assets/1-2-ply-eye-x-eye-0-tapered-thumb.jpg'
import PlyEyeXEyeTapered from '../assets/1-2-ply-eye-x-eye-tapered-0.jpg'
import PlyEyeXEye from '../assets/1-2-ply-eye-x-eye.jpg'
import PlyEyeXEyeThumb from '../assets/1-2-ply-eye-x-eye-thumb.jpg'
import PlyEyeXEyeLong from '../assets/1-2-ply-eye-x-eye-1.jpg'
import PlyEyeXEyeLongThumb from '../assets/1-2-ply-eye-x-eye-1-thumb.jpg'
import LinedTaperedEye from '../assets/lined-tapered.jpg'
import LinedTaperedEyeThumb from '../assets/lined-tapered-thumb.jpg'
import LinedFlatEye from '../assets/lined-flat-eye.jpg'
import LinedFlatEyeThumb from '../assets/lined-flat-eye-thumb.jpg'

import TwoPlyLined from '../assets/2-ply-lined.jpg'
import TwoPlyLinedThumb from '../assets/2-ply-lined-thumb.jpg'
import TwoPlyLinedShort from '../assets/2-ply-lined-1.jpg'
import TwoPlyLinedShortThumb from '../assets/2-ply-lined-1-thumb.jpg'
import TwoPlyLinedTapered from '../assets/2-ply-lined-tapered.jpg'
import TwoPlyLinedTaperedThumb from '../assets/2-ply-lined-tapered-thumb.jpg'


import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";

import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "350px",
    padding: "15px",
    backgroundImage: `url(${kam})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "white",
    fontSize: "48px",
    fontWeight: "700",
    marginBottom: "20px",
    dropShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      padding: "20px",
      height: "150px",
    },
  },
  pagesubtitle: {
    margin: "12px",
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  pagecontent: {
    padding: "5px",
  },
  bultitle: {
    backgroundColor: "#00be00",
    fontSize: "26px",
  },
  theader: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  img: {
    height: "80px",
    marginRight: "50px",
    marginLeft: "50px",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
    [theme.breakpoints.down("sm")]: {
     height: "45px"
    },
  },
  tableHeading: {
  textAlign: "center", 
  margin: "0px" 
  }, 
  card: {
    marginTop: "10px",
    marginBottom: "10px",
   
    padding: '5px',
    
    zIndex: "2"
},
}));

export default function Polysterweb() {
  const classes = useStyles();
  return (
    <div className="animate__animated animate__fadeIn">
      <Helmet>
        <title>
          Polyester Web Slings
        </title>
      </Helmet>
      <Typography align="center" className={classes.pagetitle} style={{fontSize: "48px",
    fontWeight: "700",}}>
        POLYESTER WEB SLINGS
      </Typography>

      <Container maxWidth="md" component="main">

<Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={10} md={5} sm={5}>
            <div title="Web Sling">
                <ModalImage
                      small={webSling}
                      large={webSling} hideDownload={true} hideZoom={true}
                      className={classes.card} alt="Web Sling"
                     />     
              </div>
              </Grid>  
              <Grid item  xs={10} md={5} sm={5}>
                <div title="Polyester Eye x Eye Tapered">
                <ModalImage
                      small={PlyEyeXEyeTaperedThumb}
                      large={PlyEyeXEyeTapered} hideDownload={true} hideZoom={true}
                      className={classes.card}
                      alt="Polyester Eye x Eye Tapered"
                    />   
                    </div>
              </Grid> 
        </Grid>
        
        <Grid style={{marginTop: '40px'}}>
          <Accordion
            defaultExpanded={true}
            style={{ backgroundColor: "#00be00" }}
          >
            <AccordionSummary
              style={{ height: "15px" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component={"span"} className={classes.pagesubtitle}>
                Polyester Web Sling Description
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
              <Typography component={"span"}>
                <Typography align="left" className={classes.pagesubtitle}>
                  Eye-Eye Polyester Web Slings 5:1 Design Factor, Suitable for
                  Overhead Lifting.
                </Typography>
                <Typography component={"p"} className={classes.pagecontent}>
                  Polyester and nylon slings are very similar but do have a few
                  key differences. Polyester slings cannot be used in an
                  environment that has Aldehydes or Ethers and will be
                  negatively affected by concentrated Sulfuric Acid and strong
                  Alkalis at high temperatures. Nylon slings cannot be used in
                  environments with Acid or Bleaching Agents. The amount of
                  stretch each material has also differs. Polyester stretches to
                  about 3% at rated capacity, but nylon stretches to about 10%.
                  Polyester and nylon are equal in strength.
                </Typography>
                <Typography component={"p"} className={classes.pagecontent}>
                  Lightweight and versatile, web slings are a very commonly used
                  rigging component. They are constructed of fibers woven into
                  webbing and the webbing is sewn to meet the desired
                  specifications.
                </Typography>
                <Typography component={"p"} className={classes.pagecontent}>
                  E-Rigging offers synthetic web slings sewn with polyester
                  webbing. In the past, most web slings sewn in the U.S. were
                  made of nylon webbing. For years, the rest of the world has
                  made these slings out of polyester webbing because polyester
                  stretches less. And when lifting an item, the general thought
                  is that stretch is not good. But years ago, operators in the
                  U.S. became familiar with how nylon handled and it became the
                  standard. Due to the rising cost of nylon, industry in the
                  U.S. is now moving steadily towards polyester and soon, it
                  will become the universal standard and nylon will be used far
                  less.
                </Typography>
                <Typography component={"p"} className={classes.pagecontent}>
                  One of the versatile features of making web slings is that
                  they can be sewn into different configurations, eye types and
                  multiple plies to increase strength. The two basic
                  configurations are eye x eye and endless (grommet shape). Eyes
                  can be made flat, twisted or tapered. And slings are commonly
                  made with 1, 2 or 4 plies sewn together. The most common web
                  slings are eye x eye two ply slings with flat or tapered eyes.
                </Typography>
                <Typography component={"p"} className={classes.pagecontent}>
                  In addition to adding more plies or layers of webbing to make
                  the sling stronger, wider webbing can be used. The common
                  strength of webbing used to make synthetic web slings is 9800
                  lb/in webbing. What that means is a single ply of 1 inch
                  webbing has a straight pull breaking strength of 9800 lbs. For
                  2 inch webbing, the breaking strength would be 9800 lbs x 2
                  and 4 inch webbing would be 9800 lbs x 4.
                </Typography>
                <Typography component={"p"} className={classes.pagecontent}>
                  Perhaps the only significant concern of using synthetic web
                  slings is they are more susceptible to being cut or damaged
                  than wire rope or chain slings. That being said, it important
                  to inspect these type slings regularly after use.
                  
                </Typography>
                <Typography component={"p"} align="center" className={classes.pagecontent}>
                <img src={websling} alt="" style={{ padding: "20px" }} />
                  
                </Typography>
              </Typography>
            </AccordionDetails>
           
          </Accordion>
        </Grid>    
        <EyeTypes />
      <Container maxWidth="lg">
          
    
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ margin: "10px" }}
          >
         
              <Typography align="center" style={{marginTop: '10px'}}>
            {/* <Link to="/safety-bulletin" className={classes.pagesubtitle}>POLYESTER WEB SLING SAFETY BULLETIN </Link> */}
        </Typography>
          </Grid>
  
          </Container>
       
        <div style={{ textAlign: "center", marginBottom: "5px", marginTop: "25px" }}>
          <h2  className={classes.tableHeading}>
          One Ply Eye & Eye Slings 
          </h2>
          <h3 className={classes.tableHeading}>Standard stocked slings</h3>
          <Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={10} md={4} sm={4}>
            <div title="Polyester Eye x Eye Tapered">
                <ModalImage
                      small={PlyEyeXEyeTaperedThumb}
                      large={PlyEyeXEyeTapered}
                      className={classes.card} alt="Polyester Eye x Eye Tapered "
                     />     
                     </div>
              </Grid>  
              <Grid item  xs={10} md={4} sm={4}>
                <div title="Polyester Eye x Eye Tapered">
                <ModalImage
                      small={PlyEyeXEye}
                      large={PlyEyeXEyeThumb}
                      className={classes.card}
                      alt="Polyester Eye x Eye Tapered"
                    />
                    </div>   
              </Grid> 
              <Grid item  xs={10} md={4} sm={4}>
                <div title="Polyester Eye x Eye Long">
                <ModalImage
                      small={PlyEyeXEyeLong}
                      large={PlyEyeXEyeLongThumb}
                      className={classes.card}
                      title="Polyester Eye x Eye Long"
                    />
                    </div>   
              </Grid> 
        </Grid>   
   
        </div>
        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#00be00" }}>
              <TableRow>
                <TableCell className={classes.theader}>Part Number</TableCell>
              <TableCell className={classes.theader}>Width</TableCell>
                <TableCell className={classes.theader}>Vertical WLL (lbs)</TableCell>
                <TableCell className={classes.theader}>Choker WLL (lbs)</TableCell>
                <TableCell className={classes.theader}>Vertical WLL (lbs)</TableCell>
                <TableCell className={classes.theader}>Length</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91210015
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>1,600</TableCell>
                <TableCell className={classes.tcell}>1,250</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>4'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91210020
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>1,600</TableCell>
                <TableCell className={classes.tcell}>1,250</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91210025
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>1,600</TableCell>
                <TableCell className={classes.tcell}>1,250</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91210030
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>1,600</TableCell>
                <TableCell className={classes.tcell}>1,250</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91211005
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>4'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91211010
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91211015
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91211020
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91211025
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>12'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91211030
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>16'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91211035
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91212005
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91212010
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91212015
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91212020
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>12'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91212025
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91213005
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>

                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,960</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91213010
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>

                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,960</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91213015
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>

                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,960</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>15'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91213020
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>

                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,960</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>


        <div style={{ textAlign: "center", marginBottom: "5px" }}>
          <h2  className={classes.tableHeading}>
          Two Ply Eye & Eye Slings 
          </h2>
          <h3 className={classes.tableHeading}>Standard stocked slings</h3>
        </div>
        <Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={10} md={4} sm={4}>
            <div title="Polyester Eye x Eyed Tapered Sling">
                <ModalImage
                      small={PlyEyeXEyeTaperedThumb}
                      large={PlyEyeXEyeTapered}
                      className={classes.card} alt="Polyester Eye x Eyed Tapered Sling"
                     />     
                     </div>
              </Grid>  
              <Grid item  xs={10} md={4} sm={4}>
                <div title="Polyester Eye X Eye Sling">
                <ModalImage
                      small={PlyEyeXEye}
                      large={PlyEyeXEyeThumb}
                      className={classes.card}
                    />   
                    </div>
              </Grid> 
              <Grid item  xs={10} md={4} sm={4}>
                <div title="Polyester Eye X Eye Long Sling">
                <ModalImage
                      small={PlyEyeXEyeLong}
                      large={PlyEyeXEyeLongThumb}
                      className={classes.card}
                      alt="Polyester Eye X Eye Long Sling"
                    />   
                    </div>
              </Grid> 
        </Grid>   
        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#00be00" }}>
              <TableRow>
                <TableCell className={classes.theader}>Part Number</TableCell>
                <TableCell className={classes.theader}>Width</TableCell>
                <TableCell className={classes.theader}>Vertical WLL</TableCell>
                <TableCell className={classes.theader}>Choker WLL</TableCell>
                <TableCell className={classes.theader}>Vertical WLL</TableCell>
                <TableCell className={classes.theader}>Lengths Available</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91218010
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>3'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91218015
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91218020
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91218025
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91218030
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  91219005
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>4'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91219005
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  91219010
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  91219015
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  91219020
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>12'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91219025
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>16'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91219035
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91220005
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91220010
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91220015
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  91220020
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>12'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  91220025
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91221005
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>
                <TableCell className={classes.tcell}>11,000</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>22,000</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91221010
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>
                <TableCell className={classes.tcell}>11,000</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>22,000</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91221015
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>
                <TableCell className={classes.tcell}>11,000</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>22,000</TableCell>
                <TableCell className={classes.tcell}>15'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91221020
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>
                <TableCell className={classes.tcell}>11,000</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>22,000</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ textAlign: "center", marginBottom: "5px" }}>
          <h2  className={classes.tableHeading}>
          One Ply Eye & Eye Slings Lined Bearing Points
          </h2>
          <h3 className={classes.tableHeading}>Standard stocked slings</h3>
        </div>
        <Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={10} md={4} sm={4}>
            <div title="Lined Flat Eye Sling">
                <ModalImage
                      small={LinedFlatEyeThumb}
                      large={LinedFlatEye}
                      className={classes.card} alt="Lined Flat Eye Sling"
                     />     
                     </div>
              </Grid>  
              <Grid item  xs={10} md={4} sm={4}>
                <div title="Lined Tapered Eye Sling">
                <ModalImage
                      small={LinedTaperedEyeThumb}
                      large={LinedTaperedEye}
                      className={classes.card}
                      alt="Lined Tapered Eye Sling"
                    />   
                    </div>
              </Grid> 
        </Grid>   
        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#00be00" }}>
              <TableRow>
                <TableCell className={classes.theader}>Part Number</TableCell>
              <TableCell className={classes.theader}>Width</TableCell>
                <TableCell className={classes.theader}>Vertical WLL (lbs)</TableCell>
                <TableCell className={classes.theader}>Choker WLL (lbs)</TableCell>
                <TableCell className={classes.theader}>Vertical WLL (lbs)</TableCell>
                <TableCell className={classes.theader}>Length</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91214015
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>1,600</TableCell>
                <TableCell className={classes.tcell}>1,250</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>4'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91214020
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>1,600</TableCell>
                <TableCell className={classes.tcell}>1,250</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91214025
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>1,600</TableCell>
                <TableCell className={classes.tcell}>1,250</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                  91210030
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>1,600</TableCell>
                <TableCell className={classes.tcell}>1,250</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91215010
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91215015
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91215020
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91215025
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>12'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91215030
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>16'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91215035
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>3,200</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91216005
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91216010
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91216015
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91216020
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>12'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91216025
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>

                <TableCell className={classes.tcell}>4,700</TableCell>
                <TableCell className={classes.tcell}>3,750</TableCell>
                <TableCell className={classes.tcell}>9,400</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91217005
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>

                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,960</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91217010
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>

                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,960</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91217015
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>

                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,960</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>15'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91217020
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>

                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,960</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ textAlign: "center", marginBottom: "5px" }}>
          <h2  className={classes.tableHeading}>
          Two Ply Eye & Eye Slings Lined Bearing Points
          </h2>
          <h3 className={classes.tableHeading}>Standard stocked slings</h3>
        </div>
        <Grid container spacing={4} justify="center" aignItems="center">                    
          <Grid item  xs={9} md={4} sm={4}>
            <div title="Two Ply Lined Sling">
                <ModalImage
                      small={TwoPlyLinedThumb}
                      large={TwoPlyLined}
                      className={classes.card} alt="Two Ply Lined Sling"
                     />     
                     </div>
              </Grid>  
              <Grid item  xs={9} md={4} sm={4}>
                <div title="Two Ply Lined Short Sling">
                <ModalImage
                      small={TwoPlyLinedShortThumb}
                      large={TwoPlyLinedShort}
                      className={classes.card}
                      alt="Two Ply Lined Short Sling"
                    />   
                    </div>
              </Grid> 
              <Grid item  xs={9} md={4} sm={4}>
                <div title="Two Ply Lined Tapered Sling">
                <ModalImage
                      small={TwoPlyLinedTaperedThumb}
                      large={TwoPlyLinedTapered}
                      className={classes.card}
                      alt="Two Ply Lined Tapered Sling"
                    />   
                    </div>
              </Grid> 
        </Grid>   
        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#00be00" }}>
              <TableRow>
                <TableCell className={classes.theader}>Part Number</TableCell>
                <TableCell className={classes.theader}>Width</TableCell>
                <TableCell className={classes.theader}>Vertical WLL</TableCell>
                <TableCell className={classes.theader}>Choker WLL</TableCell>
                <TableCell className={classes.theader}>Vertical WLL</TableCell>
                <TableCell className={classes.theader}>Lengths Available</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91222010
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>3'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91222015
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91222020
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91222025
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tcell}>
                91222030
                </TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>3,100</TableCell>
                <TableCell className={classes.tcell}>2,450</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91223005
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>4'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91223010
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91223015
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91223020
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91223025
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>12'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91223030
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>16'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91223035
                </TableCell>
              <TableCell className={classes.tcell}>2"</TableCell>
                <TableCell className={classes.tcell}>6,200</TableCell>
                <TableCell className={classes.tcell}>4,950</TableCell>
                <TableCell className={classes.tcell}>12,400</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91224005
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>6'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91224010
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91224015
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91224020
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>12'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91224025
                </TableCell>
              <TableCell className={classes.tcell}>3"</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>7,000</TableCell>
                <TableCell className={classes.tcell}>17,600</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91225005
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>
                <TableCell className={classes.tcell}>11,000</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>22,000</TableCell>
                <TableCell className={classes.tcell}>8'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91225010
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>
                <TableCell className={classes.tcell}>11,000</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>22,000</TableCell>
                <TableCell className={classes.tcell}>10'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91225015
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>
                <TableCell className={classes.tcell}>11,000</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>22,000</TableCell>
                <TableCell className={classes.tcell}>15'</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                91225020
                </TableCell>
              <TableCell className={classes.tcell}>4"</TableCell>
                <TableCell className={classes.tcell}>11,000</TableCell>
                <TableCell className={classes.tcell}>8,800</TableCell>
                <TableCell className={classes.tcell}>22,000</TableCell>
                <TableCell className={classes.tcell}>20'</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

       
      
      
        

        <Container
          maxWidth="md"
          style={{
            marginTop: "25px",
            marginBottom: "15px",
            padding: "15px",
            paddingBottom: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "10px solid #e4e4e4",
          }}
        >
          <div style={{ flexBasis: "none" }}>
            <p style={{ fontSize: "30px" }} align="center">
              Where to Buy
            </p>
            <Grid
              container
              direction="row"
              spacing={6}
              justify="center"
              alignItems="center"
            >
              <Grid item md={12} xs={12} style={{ flexBasis: "0" }}>
                <a
                  href="https://www.e-rigging.com/Synthetic-Lifting-Slings_c_3879.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Erigging} className={classes.img} alt="" />
                </a>
              </Grid>
              {/* <Grid item md={4} xs={12} style={{flexBasis:'0'}}>
                <a href="https://www.amazon.com/s?k=Thor-Tex" target="_blank" rel="noopener noreferrer">
                    <img src ={wtb1} className={classes.img} alt=""/></a>
                </Grid>    */}
              {/* <Grid item md={6} xs={12} style={{ flexBasis: "0" }}>
                <a
                  href="https://sldrigging.com/contactform"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={sld} className={classes.img} alt="" />
                </a>
              </Grid> */}
            </Grid>
          </div>
        </Container>
      </Container>

      <div style={{ marginBottom: "50px" }}></div>
    </div>
  );
}
