import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import {
  Container,
  TextField,
  Paper,
  Button,
  CssBaseline,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import { Link } from "react-router-dom";
import styles, { selectStyles } from "./AddCertificateStyles";
import { partArr } from "./thortexPartList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AddCertificate() {
  const initialState = {
    serial: "",
    manufacturer: "",
    part: "",
    name: "",
    vertical: "",
    choke: "",
    basket: "",
    fabricator: "",
    date: "",
    width: "",
    length: "",
    ply: "",
    lining: "",
    eyeType: "",
    description: "",
  };

  const [detail, setDetail] = useState({ part_id: "" });
  const [inputs, setInputs] = useState(initialState);
  const [serialPrefix, setSerialPrefix] = useState("");
  const [lastSerial, setLastSerial] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [printerLine, setPrinterLine] = useState("");
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSelect = (selectedOption) => {
    if (selectedOption !== null) {
      setDetail({ part_id: selectedOption.value });
    }
  };

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(lastSerial);
  };

  const clear = () => {
    setInputs(initialState);
    setPrinterLine("");
    setSerialPrefix("");
    setQuantity(1);
  };

  const timeoutSuccessMsg = (msg) => {
    setSuccessMsg(msg);
    setTimeout(() => {
      setSuccessMsg(null);
    }, 4000);
  };

  const timeoutErrorMsg = (msg) => {
    setErrorMsg(msg);
    setTimeout(() => {
      setErrorMsg(null);
    }, 4000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cert = inputs;
    setLastSerial(cert.serial);
    setInputs(initialState);

    let response = await axios.post("https://thor-tex-database.onrender.com/thortex", cert);
    timeoutSuccessMsg(response.data.success);
    if (response.data.errors) {
      timeoutErrorMsg(response.data.errors.serial.message);
    }
  };

  const handleSubmitMultiple = async (e) => {
    e.preventDefault();
    const cert = inputs;
    let arr = [];
    const forLoop = async () => {
      for (let idx = 0; idx < quantity; idx++) {
        let newSerialNum = Number(cert.serial) + idx;
        arr.push({ ...cert, serial: newSerialNum });
      }

      let response = await axios.post("https://thor-tex-database.onrender.com/thortex/certs", arr);
      if (response.data.errors) {
        return timeoutErrorMsg(response.data.errors.serial.message);
      }
      timeoutSuccessMsg(response.data.success);
      setLastSerial(arr[arr.length - 1].serial);
    };
    forLoop();
    setInputs(initialState);
  };

  useEffect(() => {
    if (detail.part_id) {
      axios.get(`https://thor-tex-database.onrender.com/thortex/parts/${detail.part_id}`).then((res) => {
        let data = res.data;
        setInputs((i) => ({ ...i, ...data, manufacturer: "Thor-tex USA" }));
        setSerialPrefix(data.serialPrefix);
        setPrinterLine(data.printerLine);
      });

      axios
        .get("https://thor-tex-database.onrender.com/lastSerial")
        .then((res) => {
          setLastSerial(res.data.serial);
        })
        .catch((err) => {
          timeoutErrorMsg(`Server is not responding ${err.message}`);
        });
    }
  }, [detail.part_id]);

  const classes = styles();
  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />

        <Grid container>
          <Grid item sm={9}>
            <Paper elevation={1} className={classes.paper}>
              <Typography component="span" variant="body1">
                {errorMsg && (
                  <Alert severity="error" style={{ marginBottom: "10px" }}>
                    {errorMsg}
                  </Alert>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Select
                      placeholder="Select Part Number"
                      styles={selectStyles}
                      options={partArr}
                      isClearable
                      onChange={handleSelect}
                    />
                  </Grid>

                  <Grid item xs={12}>
                  <h4 style={{margin: 0}}>
                      Printer Line: {printerLine}
                      </h4>
                    
                  </Grid>

                  <Grid item xs={12}>
                    <h4 style={{margin: 0}}>
                    Last Serial: {lastSerial && lastSerial}
                    </h4>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleCopy}
                      margin="0"
                      style={{ backgroundColor: "#00be11", color: "black" }}
                    >
                      Copy Serial to clipboard
                    </Button>
                  </Grid>
                  {successMsg && (
                    <Grid item xs={12}>
                      <Alert severity="success">{successMsg}</Alert>
                    </Grid>
                  )}
                </Grid>
              </Typography>

              <form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      key="Date"
                      label="Date"
                      margin="dense"
                      variant="outlined"
                      type="text"
                      value={inputs.date}
                      name="date"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Fabricator"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      type="text"
                      value={inputs.fabricator}
                      name="fabricator"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{margin: 0}}>Serial Prefix: {serialPrefix && serialPrefix}</h3>
                    <TextField
                      label="Serial Number"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      type="text"
                      value={inputs.serial}
                      placeholder="Serial Number"
                      name="serial"
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 8
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      label="Quantity"
                      fullWidth
                      type="text"
                      name="qty"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Button
                      onClick={clear}
                      variant="contained"
                      fullWidth
                      className={classes.submit}
                    >
                      Clear
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                      onClick={
                        quantity > 1 ? handleSubmitMultiple : handleSubmit
                      }
                      style={{ backgroundColor: "#00be11" }}
                    >
                      Submit
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Manufacturer"
                      variant="outlined"
                      type="text"
                      value={inputs.manufacturer}
                      placeholder="Manufacturer"
                      name="manufacturer"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Part Number"
                      variant="outlined"
                      type="text"
                      value={inputs.part}
                      placeholder="Part"
                      name="part"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      type="text"
                      value={inputs.name}
                      placeholder="name"
                      name="name"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Vertical"
                      variant="outlined"
                      type="text"
                      value={inputs.vertical}
                      placeholder="vertical"
                      name="vertical"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Choke"
                      variant="outlined"
                      type="text"
                      value={inputs.choke}
                      placeholder="choke"
                      name="choke"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Basket"
                      variant="outlined"
                      type="text"
                      value={inputs.basket}
                      placeholder="verticalB"
                      name="basket"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Width"
                      variant="outlined"
                      type="text"
                      value={inputs.width}
                      placeholder="width"
                      name="width"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Length"
                      variant="outlined"
                      type="text"
                      value={inputs.length}
                      placeholder="length"
                      name="length"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Ply"
                      variant="outlined"
                      type="text"
                      value={inputs.ply}
                      placeholder="ply"
                      name="ply"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Lining"
                      variant="outlined"
                      type="text"
                      value={inputs.lining}
                      placeholder="lining"
                      name="lining"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Eye Type"
                      variant="outlined"
                      type="text"
                      value={inputs.eyeType}
                      placeholder="eyeType"
                      name="eyeType"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Description"
                      multiline
                      variant="outlined"
                      type="text"
                      value={inputs.description}
                      placeholder="description"
                      name="description"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Accordion style={{ marginTop: "23px", padding: 0 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h3
                  style={{
                    marginTop: "3px",
                    marginBottom: "3px",
                    padding: "0",
                  }}
                >
                  Procedure
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      1. Type and/or select a part number or part name
                    </Typography>
                  </Grid>
                  <hr />
                  <Grid item xs={12}>
                    <Typography>
                      2. Click on copy serial to clipboard.
                    </Typography>
                  </Grid>
                  <hr />
                  <Grid item xs={12}>
                    <Typography>
                      3. Paste it into the form increment the number by 1
                      Example: Last Serial 100001 Enter 100002
                    </Typography>
                  </Grid>
                  <hr />
                  <Grid item xs={12}>
                    <Typography>
                      4. Make sure the first 2 numbers match the serial prefix
                      for the product listed above.
                    </Typography>
                  </Grid>
                  <hr />
                  <Typography>
                    5. Enter Date, Fabricator name, and number of serials to add
                    in the quantity field. Example: Quantity: 5, Serial Number
                    100002 will add serials 100002 - 100006
                  </Typography>
                  <hr />
                  <Grid item xs={12}>
                    <Typography>6. Ensure all information is filled out, including options below submit button.</Typography>
                  </Grid>
                  <hr />
                  <Grid item xs={12}>
                    <Typography>7. Submit the Form</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ padding: 0 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h3
                  style={{
                    marginTop: "3px",
                    marginBottom: "3px",
                    padding: "0",
                  }}
                >
                  View Certificates
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      <Link to="/operatorAllCerts">All Certificates</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AddCertificate;
