
import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from "@mui/styles";
import { createRoot } from 'react-dom/client';
import theme from './theme'
import { BrowserRouter } from 'react-router-dom'

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
  <ThemeProvider theme={theme}>
   <App />
   </ThemeProvider>
   </BrowserRouter>
);
