import React from 'react'
import { Typography, Grid, Button, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import WarningIcon from '@mui/icons-material/Warning';
import Training from './Training'
import Good from './Good'
import Sling from './Sling'
import Never from './Never'
import Stay from './Stay'
import SlingStorage from './SlingStorage'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles(theme => ({
  pageheader: {
    padding: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "30px",
    fontWeight: "700",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      padding: "20px",
    },
  },
    pagetitle:{
     color: '#231f20',
     paddingTop:'30px',
     fontSize: '45px'
   },
   pagesubtitle:{
        margin: '15px',
        fontSize: '22px'
   },
   pagecontent:{
    padding: '5px'
},
pagecaption: {
    margin: '15px',
    fontSize: '18px'
},
bultitle: {
  backgroundColor: '#00be00',
  fontSize: '26px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
},
},  
  link: {
    color: 'black',
    fontSize: '20px',
  },
  backlink: {
    float: 'right',
    textDecoration: 'none',
    fontSize: '18px',
    cursor: 'pointer'
  }
 }));

 export default function Bulletin() {
    const classes = useStyles();

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
     return(
        <div className="animate__animated animate__fadeIn">
          <Helmet>
            Polyester Web Sling Safety Bulletin
          </Helmet>
          <Typography align="center" className={classes.pageheader} >
              POLYESTER WEB SLING SAFETY BULLETIN 
          </Typography>
        
           <Container maxWidth="md" component="main" style={{marginBottom: '100px'}}>

        <Grid style={{margin: '40px'}}>    
          <Grid container spacing={2} justify="center" alignItems="center" 
          style={{backgroundColor: '#fa8b1c', border: '3px solid grey'}}>                    
          <Grid item  xs={12} sm={6} md={3} style={{textAlign:'center'}}>
             <WarningIcon style={{fontSize: '200px'}} />  
            </Grid>  
           <Grid item  xs={12} sm={6} md={9} id="BacktoTop">
            Lifting & lifting systems require the use of several tools, variables & devices, and polyester web sling(s) are only one part of that system. It is critical that you read and understand this bulletin prior to using polyester web slings and the failure to do so, may result in severe INJURY or DEATH due to sling failure and/or loss of the load
            </Grid>  
            </Grid>
        </Grid>
       
        <Grid  container spacing={2} justify="center" alignItems="center" >     
          <Typography className={classes.pagetitle}>The 6 Areas of Concentration</Typography>               
          <Grid item  xs={12} sm={6} md={6} className={classes.pagecontent}>
             <ol>
               <a href="#TRAINING&KNOWLEDGE" className={classes.link}><li>TRAINING & KNOWLEDGE </li></a>
               <a href="#GOOD INSPECTION PRACTICES" className={classes.link}> <li>GOOD INSPECTION PRACTICES</li></a>
               <a href="#SLING PROTECTION" className={classes.link}><li>SLING PROTECTION</li>  </a>                     
              </ol>
              </Grid>  
              <Grid item  xs={12} sm={6} md={6} className={classes.pagecontent}>
              <ol start="4">
              <a href="#NEVER EXCEED RATED CAPACITY" className={classes.link}> <li> NEVER EXCEED RATED CAPACITY</li></a>   
              <a href="#STAY CLEAR OF LOAD" className={classes.link}>  <li>STAY CLEAR OF LOAD</li></a>   
              <a href="#SLING STORAGE" className={classes.link}>  <li>SLING STORAGE</li>  </a>                      
              </ol>
              </Grid>  
        </Grid>
        <Training/>
        <Button color="primary" onClick={scrollToTop}
          className={classes.backlink}>Back to Top</Button>
        <Good/> 
        <Button color="primary" onClick={scrollToTop} className={classes.backlink}>Back to Top</Button>   
        <Sling/>
        <Button color="primary" onClick={scrollToTop} className={classes.backlink}>Back to Top</Button>   
        <Never/>
        <Button color="primary" onClick={scrollToTop} className={classes.backlink}>Back to Top</Button>   
        <Stay/>
        <Button color="primary" onClick={scrollToTop} className={classes.backlink}>Back to Top</Button>   
        <SlingStorage/>
        <Button color="primary" onClick={scrollToTop} className={classes.backlink}>Back to Top</Button>   
        </Container>
        </div>
     )
 }