import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { animateScroll as scroll } from 'react-scroll';
import JotformEmbed from 'react-jotform-embed';


function Copyright() {
  return (
    <Typography  component={'span'} variant={'body2'} style={{color: 'black'}}>      
       <Link to='/privacy-policy' style={{textDecoration: 'none', color: 'black'}}>Privacy Policy |</Link>
       <Link to='/terms-of-use' style={{textDecoration: 'none', color: 'black'}}> Terms of Use | </Link>
       <Box display={{ xs: 'block', sm: 'none' }}><br/></Box>
      {'Copyright ©     '}
      <Link to='/' color="inherit" 
      style={{textDecoration: 'none', color: 'black'}}>
         Sea-Land Distributors, LLC. 
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    footer: {        
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    headerlink:{
      textDecoration: 'none',
      color: 'black',
      fontSize: '25px',
  },
    link:
    { lineHeight:'1.5',
      textDecoration: 'none',
      color: 'black',
      fontSize: '16px',
    },
    textfield:{
      margin: '5px',
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '20px',
      paddingLeft: '8px'
  },
    signup:{
      color: 'white', 
      border: '2px solid white', 
      padding: '4px',
      margin: '3px',
    }, 
    form: {
      backgroundColor: "#FFF",
      fontSize: "200px;"
    }
  }));

  const handleClick = (event) => {
    scroll.scrollToTop();
  };

export default function Footer() {
  const classes = useStyles(); 
  return (
    <div style={{backgroundColor: '#00be00'}}>
    <Container maxWidth="lg" component="footer" className={classes.footer} >
        <Grid container spacing={2}>        
            <Grid item xs={6} sm={3} md={4} lg={4} >
              <Typography  component={'span'} variant={'body2'}  className={classes.headerlink} style={{ fontSize: '25px'}}>
               THOR-TEX              
              </Typography>
              <ul>    
            <Link to='/' className={classes.link} onClick={handleClick}><li>Home  </li></Link>  
              <a href='/#contact' className={classes.link}><li>Contact  </li> </a>  
              <a href='/#about' className={classes.link}><li>About </li> </a>
              <a href='/#wheretobuy' className={classes.link}><li>Where to Buy </li>  </a>               
              </ul>
            </Grid>  
            <Grid item xs={6} sm={3} md={4} lg={4}>
              <Typography  component={'span'} variant={'body2'} gutterBottom className={classes.headerlink} style={{ fontSize: '25px'}}>                
              Quick Links
              </Typography>                 
              <ul>
              <Link to='/polyester-web' className={classes.link}><li>Polyester Web Slings</li></Link>  
              <Link to='/safety-bulletin' className={classes.link}><li>PWS Safety Bulletin</li></Link>
              <Link to='/polyester-round' className={classes.link}><li>Polyester Round Slings  </li></Link>    
              <Link to='/fabrication' className={classes.link}><li>Fabrication Standards  </li></Link>    
              <Link to='/online-certs' className={classes.link}><li>Online Certificates </li></Link>
              </ul>              
            </Grid>  
            <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography   variant={'body2'} className={classes.headerlink} style={{paddingLeft:'20px', paddingBottom: '5px', fontSize: "25px"}}> N E W S L E T T E R</Typography>             
            <Typography  variant={'body2'} className={classes.link} style={{paddingLeft:'20px'}}>Sign up for new THOR-TEX releases</Typography> 
            <JotformEmbed src="https://form.jotform.com/222425473713150" />             
        </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
        <div style={{paddingBottom: '30px'}}></div>
      </Container>
      </div>
  );
}