import React from "react";
import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Helmet } from 'react-helmet'


import kam1 from "../assets/kam-1.jpg";
import badge from "../assets/WSTDA-WS-1.png";

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    padding: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "30px",
    fontWeight: "700",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      padding: "20px",
    },
  },
  pagesubtitle: {
    margin: "12px",
    fontSize: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  pagecontent: {
    padding: "5px",
  },
  bultitle: {
    backgroundColor: "#00be00",
    fontSize: "26px",
  },
  theader: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  img: {
    height: "40px",
    marginRight: "50px",
    marginLeft: "50px",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
  },
}));

const Fabrication = () => {
  const classes = useStyles();

  return (
    <div style={{ marginBottom: "25px" }}>
      <Helmet>
        <title>
          Fabrication
        </title>
      </Helmet>
      <Typography align="center" className={classes.pagetitle}>
        FABRICATION
      </Typography>

      <Container maxWidth="lg" component="main">
        <Accordion
          defaultExpanded={true}
          style={{ backgroundColor: "#00be00" }}
          disabled
        >
          <AccordionSummary
            style={{ height: "15px", padding: "12px", opacity: "1" }}
            id="panel1a-header"
          >
            <Typography
              style={{ padding: "12px;", color: "black" }}
              component={"span"}
              className={classes.pagesubtitle}
            >
              <strong>THOR-TEX</strong> Fabrication Standards
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#FFF" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <img
                  src={kam1}
                  alt="Fabricator Kam sewing slings"
                  width="100%"
                />
              </Grid>
              <Grid container direction="column" xs={12} sm={12} md={6}>
                <Grid item>
                  <Typography
                    align="left"
                    className={classes.pagecontent}
                    style={{ marginTop: "7px" }}
                  >
                    <span><strong>THOR-TEX</strong></span> takes pride in helping our customers lift, tow, and
                    haul almost anything, safely and reliably, time after time.
                    Our lifting slings are manufactured from the highest-quality
                    100% polyester webbing we can source. We inspect all
                    materials before our skilled craftsmen construct each strap
                    by hand on our industrial sewing machines right here in New
                    Orleans.
                  </Typography>
                  <Typography align="left" className={classes.pagecontent}>
                    Our lifting slings are engineered with American-made
                    high-visibility strap material for easy inspection and added
                    safety. We also offer both 1- and 2-ply slings, with or
                    without Cordura-lined anti-abrasion eye loops to ensure
                    longevity and reliability in a range of applications. The
                    100% polyester construction also means our lifting straps
                    will have very little stretch, increasing the lifespan of
                    our products.
                  </Typography>

                  <Typography align="left" className={classes.pagecontent}>
                    All our sling straps are manufactured in accordance with
                    best practices outlined by the Web Sling and Tie Down
                    Association (WSTSA) WS-1 standards, approved by OSHA for
                    overhead lifting applications.
                  </Typography>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: "center" }}>
                    <img src={badge} alt="Fabricator Kam sewing slings" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Container>
    </div>
  );
};

export default Fabrication;
