import React from "react";
import { Container, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import about from "./../assets/about.jpg";

const StyledAboutDiv = styled('div')({
  backgroundColor: "#d6d9dd",
  display: 'flex',
  minHeight: '60vh',
  paddingTop: '10px',
  flexGrow: '1',
});

const StyledImage = styled("img")({
  maxHeight: "300px",
  width: "auto",
  '@media (max-width:960px)': {
    maxHeight: "180px",
  },
});

const StyledTextContainer = styled('div')({
  padding: '5%',
});

const StyledTypography = styled(Typography)({
  fontSize: "50px",
  fontWeight: "700",
  '@media (max-width:600px)': {
    fontSize: "40px",
  },
});

const StyledSubtitle = styled(Typography)({
  fontSize: "20px",
  paddingBottom: "40px",
  '@media (max-width:600px)': {
    paddingBottom: "20px", // Adjust the padding as needed
  },
});

const About = () => {
  return (
    <StyledAboutDiv id="about">
      <Container maxWidth="lg" style={{ flexGrow: '1' }}>
        <Grid container spacing={4}>
          <Grid item lg={5} xs={12} md={6} style={{ display: 'flex', alignItems: 'center', }}>
            <StyledImage src={about} alt="aboutimg" />
          </Grid>
          <Grid item lg={7} xs={12} md={6}>
            <StyledTextContainer>
              <StyledTypography align="center">
                About Us
              </StyledTypography>
              <StyledSubtitle style={{ paddingLeft: "50px" }}>
                THOR-TEX is a registered trademark and brand of Sea-Land Distributors located in New Orleans.  
                After importing polyester web slings from producers in the Far East for many years, a sewing department was
                created within the company to produce a higher quality product using American-made webbing. 
                All slings are fabricated in accordance with the guidelines and standards established by the Web Sling Tie-Down 
                Association that is recognized by OSHA and all other safety agencies as the regulating authority for these products.
              </StyledSubtitle>
            </StyledTextContainer>
          </Grid>
        </Grid>
      </Container>
    </StyledAboutDiv>
  );
};

export default About;
