import React, { useState } from "react";
import "./../../App.css";
import CertDetail from "./CertDetail";
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Gallary from './Gallary'
import axios from 'axios'

export const ValidForm = () => {
  const [cert, setCert] = useState("");
  const [certError, setCertError] = useState("");
  const [certificate, setCertificate] = useState(null)

  const handleChange = (e) => {
    setCert(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault()
    let response = await axios.get(`https://thor-tex-database.onrender.com/thortex/${cert}`)
    console.log(response.data)
    setCertificate(response.data[0])
    if (response.data.length === 0) {
      setCertError("Invalid Serial Number")
    }
  };
  const val = 8 - cert.length;
  return (
    <div style={{textAlign:'center', display:'block'}} align="center">      
      <form id="form">
          <div align="center">                    
           <Paper elevation={3} className="tpaper" align="center" >
           <Gallary/>
          <TextField 
          type="number" style={{width:'60%', marginTop:'10px', paddingTop:'12px'}} className="tho-input"            
          label="Enter a Serial Number (########)" 
          value={cert}
          onChange={handleChange}  />
          <div style={{ padding: "3px" }}>
          {cert.length && cert.length < 9 ? val + " digits remanining" : null} 
          </div>
           <div style={{ color: "red", padding: "3px" }}>
           {cert.length > 8 ? "Serial number must be less than 9 digits" : null}
          </div>            
          <div style={{ color: "red", padding: "5px" }}>
            {certError}
          </div>
          <button type="submit"
            
           onClick={handleSearch} className="tho-button" style={{marginBottom:'6px'}}>
          Submit
        </button>
          </Paper>            
        </div>  
      </form>  
          {certificate && <CertDetail cert={certificate}/>}
        
        
    </div>
  );
}
