import React from 'react'
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(theme => ({
    bultitle: {
        backgroundColor: '#00be00',
        fontSize: '26px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
      },
      },
   pagecontent:{
    
}, 
pagesubtitle:{
    
    fontSize: '22px'
},
}));

export default function Training() {
    const classes = useStyles();
    return (
        <div id="TRAINING&KNOWLEDGE">
            <br/><br/><br/>
        <Accordion defaultExpanded	= {true} style={{ backgroundColor: '#00be00', margin: '0px', padding: '0px'}}>
        <AccordionSummary style={{height: '15px'}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={'span'}
>
          <ol className={classes.bultitle}><li>TRAINING & KNOWLEDGE</li></ol>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#ffffff'}}>
        <Typography component={'span'}
 className={classes.pagecontent}>
           Knowledge is acquired through training. Sling users must be knowledgeable in the safe and proper use of polyester web slings to prevent injury and death to you and others. In addition to the information found in this bulletin, polyester web sling users should also read and understand the standards and guidance provided in:           
              <ul style={{listStyleType:'disc', paddingLeft:'30px'}}>
             <li>ASME B30.9-5 Synthetic Webbing Slings: Selection, Use & Maintenance</li>
             <li>OSHA 29 CFR 1910.184: Slings</li>
             <li>OSHA Guidance On Safe Sling Use</li>
              </ul> 
            </Typography>
         </AccordionDetails>
        </Accordion>
        <Grid> 
        </Grid>
    </div>
    )
}
