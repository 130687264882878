import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Header1 from '../assets/Header1.jpg'
import Header2 from '../assets/Header2.jpg'
import Header3 from '../assets/Header3.jpg' 
import About from './About';
import Contact from './Contact';
import Wheretobuy from './Wheretobuy';
import Backtotop from './Backtotop'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Helmet } from 'react-helmet'


const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  // top: 'calc(50% - 15px)',
  top: '5%',
  width: '3%',
  height: '100%',
  color:'#00be00',
  cursor: 'pointer',
  '&hover':{
    top:0,
    bottom:0,
    backgroundColor:'lightgrey',
    opacity:'.4',
    
  }
 
};
function Home () {
   return (
       <div>
         <Helmet>
           <title>Thor-Tex</title>
         </Helmet>
       <Backtotop/>                        
       <Carousel 
       autoPlay
        transitionTime={1500}
        interval={3500}
        infiniteLoop
        showThumbs={false}
        showStatus={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
                     <ArrowBackIosIcon onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15, }}/>
          )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (        
                  <ArrowForwardIosIcon onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}/>
          )
      }>  
            
            <div><img src={Header1} alt="" /></div>
             <div><img src={Header2} alt=""/></div>
            <div><img src={Header3} alt=""/></div> 
       </Carousel>   
         <Wheretobuy/>              
        <Contact/> 
         <About/>  
     </div>
     );
   }
  
 export default Home;

 