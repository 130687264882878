import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const AllCerts = () => {
  const [certs, setCerts] = useState([]);

  useEffect(() => {
    const getAllCerts = async () => {
      let res = await axios.get("https://thor-tex-database.onrender.com/allCerts");
      setCerts(res.data);
    };

    getAllCerts();
  }, []);

  const handleDelete = async (id) => {
    setCerts(certs.filter((c) => {
      return c._id !== id
    }
    ).reverse())
    await axios.delete(`https://thor-tex-database.onrender.com/thortex/${id}`)
  }
    

  const useStyles = makeStyles((theme) => ({
    mystyle: {
      padding: "7px",
      border: "4px solid black",
      fontSize: "18px",
      fontFamily: "Roboto",
      [theme.breakpoints.down("sm")]: {
        padding: "5px",
        border: "1px solid black",
        fontSize: "12px",
      },
    },
    padding: {
      padding: "50px",
      backgroundColor: "rgb(250,250,250,0.7)",
      background: "rgb(150,150,150)",
      [theme.breakpoints.down("sm")]: {
        padding: "12px",
      },
    },
    title: {
      fontSize: "32px",
      fontFamily: "Roboto",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    subtitle: {
      fontSize: "25px",
      fontFamily: "Roboto",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  }));

  const classes = useStyles();
  return (
    <Container>
      <h1>All Certificates</h1>

      <Box display={{ xs: "none", sm: "block" }}>
        <table
          style={{
            margin: "20px 0",
            textAlign: "center",
            borderCollapse: "collapse",
            backgroundColor: "white",
          }}
          className="animate__animated animate__zoomIn"
        >
          <tbody>
            <tr>
              <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                Serial #
              </td>
              <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                Date
              </td>
              <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                Part #
              </td>
              <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                Name
              </td>
              <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                Vertical WLL
              </td>
              <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                Choker WLL
              </td>
              <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                Vertical Basket WLL
              </td>
              <td className={classes.mystyle} style={{ fontWeight: "bold" }}>
                Manufacturer
              </td>
            </tr>

            {certs.reverse().map((c) => (
              <>
                <tr key={c._id} style={{backgroundColor: c.serial % 2 === 0 ? "lightgrey" : "white"}}>
                  <td
                    className={classes.mystyle}
                    style={{ backgroundColor: "yellow" }}
                  >
                    <strong>{c.serial}</strong>
                  </td>
                  <td className={classes.mystyle}>{c.date}</td>
                  <td className={classes.mystyle}>{c.part}</td>
                  <td className={classes.mystyle}>{c.name}</td>
                  <td className={classes.mystyle}>{c.vertical}</td>
                  <td className={classes.mystyle}>{c.choke}</td>
                  <td className={classes.mystyle}>{c.basket}</td>
                  <td className={classes.mystyle}>{c.manufacturer}</td>
                  <td className={classes.mystyle}><button onClick={() => handleDelete(c._id)}>Delete</button></td>
                </tr>
                </>
            ))}
          </tbody>
        </table>
      </Box>
    </Container>
  );
};

export default AllCerts;
