import React from "react";
import { Container, Typography, Grid, TableBody } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import sld from './../assets/sld-logo.svg'
import Erigging from './../assets/erigging.png'
import ModalImage from "react-modal-image";
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    padding: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "30px",
    fontWeight: "700",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      padding: "20px",
    },
  },
  pagesubtitle: {
    padding: "10px",
    fontSize: "22px",
  },
  pagecontent: {
    paddingBottom: "20px",
    fontSize: "18px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  theader: {
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  tcell: {
    textAlign: "center",
    padding: "5px",
  },
  imgs: {
    height: "300px",
    margin: "10px",
    
  },
  img:{
    height: '80px',
    marginRight: '50px',
    marginLeft: '50px',
    '&:hover': {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
    [theme.breakpoints.down("sm")]: {
      height: "45px"
     },
}
}));

export default function Polysterround() {
  const classes = useStyles();
  return (
    <div className="animate__animated animate__fadeIn">
      <Helmet>
        <title>Polyester Round Slings</title>
      </Helmet>
      <Typography align="center" className={classes.pagetitle}>
        POLYESTER ROUND SLINGS
      </Typography>

      <Container maxWidth="lg">
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} sm={2} md={3} style={{ textAlign: "center" }}>
            <div title="2650 lbs Polyester Round Sling">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/2650-lbs-Polyester-Round-Sling.jpg?v=1646856687"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/2650-lbs-Polyester-Round-Sling.jpg?v=1646856687"
              className={classes.imgs} alt="2650 lbs Polyester Round Sling"
            />     
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={3} style={{ textAlign: "center" }}>
          <div title="5300 lbs Polyester Round Sling">
          <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/10-foot-X-5300-lb-Round-Sling.jpg?v=1646856688"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/10-foot-X-5300-lb-Round-Sling.jpg?v=1646856688"
              className={classes.imgs} alt="5300 lbs Polyester Round Sling"
            />
            </div>    
          </Grid>
          <Grid item xs={12} sm={2} md={3} style={{ textAlign: "center" }}>
            <div title="8400 lbs Polyester Round Sling">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/10-foot-X-8400-lb-Round-Sling.jpg?v=1646856690"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/10-foot-X-8400-lb-Round-Sling.jpg?v=1646856690"
              className={classes.imgs} alt="8400 lbs Polyester Round Sling"
            />    
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={3} style={{ textAlign: "center" }}>
            <div title="10600 lbs Polyester Round Sling">
          <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/10600-lbs-Polyester-Round-Sling.jpg?v=1678786787"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/10600-lbs-Polyester-Round-Sling.jpg?v=1678786787"
              className={classes.imgs} alt="10600 lbs Polyester Round Sling"
            />   
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} sm={5} md={5} align="center">
            <img
              src="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/round_sling_hitch_types.jpg?v=1646766994"
              alt="Round Sling Hitch Types Diagram"
              style={{
                height: "200px",
                marginBottom: "20px",
          
              }}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <h3 style={{marginBottom: "5px", textAlign: "center"}}>Standard Stocked Slings</h3>
            <TableContainer
              component={Paper}
              style={{ marginBottom: "30px", maxWidth: "100%" }}
              align="center"
            >
              <Table
                className={classes.table}
                aria-label="simple table"
                style={{ padding: "5px" }}
              >
                <TableBody>
                  <TableRow style={{ backgroundColor: "#00be00" }}>
                  <TableCell className={classes.theader}>
                      Part Number
                    </TableCell>
                    <TableCell className={classes.theader}>
                      Sling Color
                    </TableCell>
                    <TableCell className={classes.theader}>
                      Vertical WLL (lbs)
                    </TableCell>
                    <TableCell className={classes.theader}>                      
                      Choker WLL (lbs)
                    </TableCell>
                    <TableCell className={classes.theader}>                      
                      Basket WLL (lbs)
                    </TableCell>
                    <TableCell className={classes.theader}>                      
                      Lengths Available
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91100305</TableCell>
                    <TableCell className={classes.tcell}>Purple </TableCell>
                    <TableCell className={classes.tcell}>2600</TableCell>
                    <TableCell className={classes.tcell}>2100</TableCell>
                    <TableCell className={classes.tcell}>5300</TableCell>
                    <TableCell className={classes.tcell} >3'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91100320</TableCell>
                    <TableCell className={classes.tcell}>Purple </TableCell>
                    <TableCell className={classes.tcell}>2600</TableCell>
                    <TableCell className={classes.tcell}>2100</TableCell>
                    <TableCell className={classes.tcell}>5300</TableCell>
                    <TableCell className={classes.tcell} >6'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91100340</TableCell>
                    <TableCell className={classes.tcell}>Purple </TableCell>
                    <TableCell className={classes.tcell}>2600</TableCell>
                    <TableCell className={classes.tcell}>2100</TableCell>
                    <TableCell className={classes.tcell}>5300</TableCell>
                    <TableCell className={classes.tcell} >10'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91101305</TableCell>
                    <TableCell className={classes.tcell}>Green </TableCell>
                    <TableCell className={classes.tcell}>5300</TableCell>
                    <TableCell className={classes.tcell}>4200</TableCell>
                    <TableCell className={classes.tcell}>10600</TableCell>
                    <TableCell className={classes.tcell} >3'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91101310</TableCell>
                    <TableCell className={classes.tcell}>Green </TableCell>
                    <TableCell className={classes.tcell}>5300</TableCell>
                    <TableCell className={classes.tcell}>4200</TableCell>
                    <TableCell className={classes.tcell}>10600</TableCell>
                    <TableCell className={classes.tcell} >4'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91101320</TableCell>
                    <TableCell className={classes.tcell}>Green </TableCell>
                    <TableCell className={classes.tcell}>5300</TableCell>
                    <TableCell className={classes.tcell}>4200</TableCell>
                    <TableCell className={classes.tcell}>10600</TableCell>
                    <TableCell className={classes.tcell} >6'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91101330</TableCell>
                    <TableCell className={classes.tcell}>Green </TableCell>
                    <TableCell className={classes.tcell}>5300</TableCell>
                    <TableCell className={classes.tcell}>4200</TableCell>
                    <TableCell className={classes.tcell}>10600</TableCell>
                    <TableCell className={classes.tcell} >8'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91101340</TableCell>
                    <TableCell className={classes.tcell}>Green </TableCell>
                    <TableCell className={classes.tcell}>5300</TableCell>
                    <TableCell className={classes.tcell}>4200</TableCell>
                    <TableCell className={classes.tcell}>10600</TableCell>
                    <TableCell className={classes.tcell} >10'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91102310</TableCell>
                    <TableCell className={classes.tcell}>Yellow</TableCell>
                    <TableCell className={classes.tcell}>8400</TableCell>
                    <TableCell className={classes.tcell}>6700</TableCell>
                    <TableCell className={classes.tcell}>16800</TableCell>
                    <TableCell className={classes.tcell} >4'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91102320</TableCell>
                    <TableCell className={classes.tcell}>Yellow</TableCell>
                    <TableCell className={classes.tcell}>8400</TableCell>
                    <TableCell className={classes.tcell}>6700</TableCell>
                    <TableCell className={classes.tcell}>16800</TableCell>
                    <TableCell className={classes.tcell} >6'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91102330</TableCell>
                    <TableCell className={classes.tcell}>Yellow</TableCell>
                    <TableCell className={classes.tcell}>8400</TableCell>
                    <TableCell className={classes.tcell}>6700</TableCell>
                    <TableCell className={classes.tcell}>16800</TableCell>
                    <TableCell className={classes.tcell} >8'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91102340</TableCell>
                    <TableCell className={classes.tcell}>Yellow</TableCell>
                    <TableCell className={classes.tcell}>8400</TableCell>
                    <TableCell className={classes.tcell}>6700</TableCell>
                    <TableCell className={classes.tcell}>16800</TableCell>
                    <TableCell className={classes.tcell} >10'</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tcell}>91103340</TableCell>
                    <TableCell className={classes.tcell}>Tan</TableCell>
                    <TableCell className={classes.tcell}>10600</TableCell>
                    <TableCell className={classes.tcell}>8500</TableCell>
                    <TableCell className={classes.tcell}>21200</TableCell>
                    <TableCell className={classes.tcell} >10'</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Accordion
            defaultExpanded={true}
            style={{ backgroundColor: "#00be00" }}
          >
            <AccordionSummary
              style={{ height: "15px" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component={"span"} className={classes.pagesubtitle}>
              Endless Polyester Round Slings 5:1 Design Factor, Suitable for
          Overhead Lifting.
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
              <Typography component={"span"} style={{marginTop: "20px"}}>
                <Typography className={classes.pagecontent} align="left">
                Strong, lightweight and relatively low cost are what makes the
                polyester roundsling popular today. Roundslings are constructed by
                using loosely twisted strands of high strength polyester fibers and
                using a specially designed machine, the strands are pulled through a
                tough, woven tube to create a strand bundle. Altogether, the bundled
                polyester fiber strands and tube material form to make a strong, light
                and high performance lifting sling.
                </Typography>
                <Typography className={classes.pagecontent} align="left">
                 Though the sling can be configured
                to work as an eye x eye type sling, polyester roundslings are most
                commonly offered as an endless grommet shape. The more fiber strands
                contained inside the tube, the higher the capacity of the sling. And
                to distinguish the different capacity slings from one another, the
                world has pretty much settled on a color scheme for each of the
                standard capacities.
                 </Typography>
                 <Typography className={classes.pagecontent} align="left">
                 For example, purple tubing is the color for
                roundslings with a vertical capacity of 2,600 pounds, green is 5,400
                pounds, yellow is 8,400 pounds and tan is 10,600 pounds. These are also
                the colors and sizes offered by E-Rigging. Each of these slings come
                in various lengths.
        </Typography>
              </Typography>
            </AccordionDetails>
           
          </Accordion>
        

        <Container
        maxWidth="md"
        style={{
          marginBottom:'15px',
          padding:'15px',
          display: "flex",
          alignItems: "center", justifyContent:'center', border:'10px solid #e4e4e4'
        }}
      >
        <div style={{ flexBasis: "none", paddingBottom: "30px" }}>   
                <p  style={{fontSize:'30px'}} align="center"  >
                Where to Buy
                </p>   
            <Grid container direction="row" spacing={6} justify="center" alignItems="center" >           
                <Grid item md={12} xs={12} style={{flexBasis:'0'}} >
                <a href="https://www.e-rigging.com/Synthetic-Lifting-Slings_c_3879.html" target="_blank" rel="noopener noreferrer">
                    <img src ={Erigging} className={classes.img} alt=""/></a>
                </Grid>
                {/* <Grid item md={4} xs={12} style={{flexBasis:'0'}}>
                <a href="https://www.amazon.com/s?k=Thor-Tex" target="_blank" rel="noopener noreferrer">
                    <img src ={wtb1} className={classes.img} alt=""/></a>
                </Grid>    */}
                {/* <Grid item md={6} xs={12} style={{flexBasis:'0'}}>
                <a href="https://sldrigging.com/contactform" target="_blank" rel="noopener noreferrer">
                    <img src ={sld} className={classes.img} alt=""/></a>
                </Grid> */}
            </Grid>    
            </div>   
            </Container>
      </Container>
    </div>
  );
}
