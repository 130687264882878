import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalImage from "react-modal-image";
import Img1 from "./../../assets/img/1.png";
import Img2 from "./../../assets/img/2.png";
import Img3 from "./../../assets/img/3.png";
import Img4 from "./../../assets/img/4.png";
import Img5 from "./../../assets/img/5.png";
import Img6 from "./../../assets/img/6.png";
import Img7 from "./../../assets/img/7.png";
import Img8 from "./../../assets/img/8.png";
import Img9 from "./../../assets/img/9.png";
import Img10 from "./../../assets/img/10.png";
import Img11 from "./../../assets/img/11.png";
import Img12 from "./../../assets/img/12.png";
import Img13 from "./../../assets/img/13.png";

import HImg1 from "./../../assets/img/himg/1.jpg";
import HImg2 from "./../../assets/img/himg/2.jpg";
import HImg3 from "./../../assets/img/himg/3.jpg";
import HImg4 from "./../../assets/img/himg/4.jpg";
import HImg5 from "./../../assets/img/himg/5.jpg";
import HImg6 from "./../../assets/img/himg/6.jpg";
import HImg7 from "./../../assets/img/himg/7.jpg";
import HImg8 from "./../../assets/img/himg/8.jpg";
import HImg9 from "./../../assets/img/himg/9.jpg";
import HImg10 from "./../../assets/img/himg/10.jpg";
import HImg11 from "./../../assets/img/himg/11.jpg";
import HImg12 from "./../../assets/img/himg/12.jpg";
import HImg13 from "./../../assets/img/himg/13.jpg";

const useStyles = makeStyles((theme) => ({
  bultitle: {
    backgroundColor: "#00be00",

    fontSize: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  pagecontent: {},
  pagesubtitle: {
    fontSize: "22px",
  },
  caption: {
    textAlign: "center",
    fontWeight: "bold",
  },
}));

export default function Training() {
  const classes = useStyles();
  return (
    <div id="GOOD INSPECTION PRACTICES">
      <br />
      <br />
      <br />
      <Accordion
        defaultExpanded={true}
        style={{ backgroundColor: "#00be00" }}
      >
        <AccordionSummary
          style={{ height: "15px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={'span'}
>
            <ol start="2" className={classes.bultitle}>
              <li>GOOD INSPECTION PRACTICES</li>
            </ol>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
          <Grid container>
            <Typography component={'span'}
 className={classes.pagecontent}>
              Because polyester web slings are made from synthetic fibers, they
              are vulnerable to damage in a variety of ways. Before using a
              sling to make a lift, it is necessary to inspect the sling for
              damage prior to use to prevent
              <strong> injury</strong> or <strong>death</strong> to you and
              others.Inspection requires you to <strong>LOOK</strong> &{" "}
              <strong>FEEL</strong> over the entirelength of the sling on both
              sides. During inspection, should you identify any of the following
              conditions, the sling is to be removed from service in accordance
              with ASME B30.9-5.9.4.
            </Typography>

            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              style={{ marginTop: "10px" }}
            >
              <Grid item xs={6} sm={6} md={3}>
                <div title="Melting or charring">
                <ModalImage
                  small={Img1}
                  large={HImg1}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Melting or charring"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Melting or charring
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Acid or Alkali Burns">
                <ModalImage
                  small={Img6}
                  large={HImg6}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Acid or Alkali Burns"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Acid or Alkali Burns
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Broken or Worn Stitches">
                <ModalImage
                  small={Img7}
                  large={HImg7}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Broken or Worn Stitches"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Broken or Worn Stitches
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Excessive Abrasive Wear">
                <ModalImage
                  small={Img8}
                  large={HImg8}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Excessive Abrasive Wear"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Excessive Abrasive Wear
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Weld Spatter">
                <ModalImage
                  small={Img9}
                  large={HImg9}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Weld Spatter"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Weld Spatter
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Exposed Red Core Yarns">
                <ModalImage
                  small={Img10}
                  large={HImg10}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Exposed Red Core Yarns"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Exposed Red Core Yarns
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Holes/Punctures">
                <ModalImage
                  small={Img2}
                  large={HImg2}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Holes/Punctures"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Holes/Punctures
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Cuts or Tears">
                <ModalImage
                  small={Img3}
                  large={HImg3}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Cuts or Tears"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Cuts or Tears
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Snags">
                <ModalImage
                  small={Img4}
                  large={HImg4}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Snags"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>Snags</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="UV Degradation">
                <ModalImage
                  small={Img11}
                  large={HImg11}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="UV Degradation"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  UV Degradation
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Crushed Webbing">
                <ModalImage
                  small={Img12}
                  large={HImg12}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Crushed Webbing"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Crushed Webbing
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Embedded Materials">
                <ModalImage
                  small={Img13}
                  large={HImg13}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Embedded Materials"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>
                  Embedded Materials
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <div title="Knots">
                <ModalImage
                  small={Img5}
                  large={HImg5}
                  hideDownload={true}
                  hideZoom={true}
                  className={classes.card}
                  alt="Knots"
                />
                </div>
                <Typography component={'span'}
 className={classes.caption}>Knots</Typography>
              </Grid>
            </Grid>

            <Typography component={'span'}
 className={classes.pagecontent}>
              Other removal criteria:
            </Typography>
            <Typography component={'span'}

              className={classes.pagecontent}
              style={{ paddingLeft: "50px" }}
            >
              <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
                <li>
                  Slings with fittings that are pitted, corroded, cracked, bent,
                  twisted, gouged or broken.
                </li>
                <li>
                  For slings with hooks sewn-in, apply removal criteria for
                  hooks as stated in ASME B30.10.
                </li>
                <li>
                  For other sewn-in rigging hardware, apply removal criteria as
                  stated ASME B30.26.
                </li>
                <li>
                  Other conditions, including visible damage, that cause doubt
                  as to the continued use of the sling.
                </li>
                <br />
              </ul>
            </Typography>
            <Typography component={'span'}
 className={classes.pagecontent}>
              Polyester web slings should be inspected as follows:
            </Typography>
            <Typography component={'span'}
 className={classes.pagecontent}>
              <u>Intially</u>—When slings are received by a qualified person to
              ensure there is no damage and that the sling meets the
              requirements for its intended use.
            </Typography>
            <Typography component={'span'}
 className={classes.pagecontent}>
              <u>Frequently</u>—Polyester web slings should be inspected before
              using each shift or each day.
            </Typography>
            <Typography component={'span'}
 className={classes.pagecontent}>
              <u>Periodically</u>—Polyester web slings are to be inspected by a
              qualified person,{" "}
              <u>
                other than the person who conducts the frequent inspections.
              </u>{" "}
              Periodic inspections are to be done annually for normal service
              and monthly to quarterly for severe service. Inspection intervals
              are not to exceed one year. For periodic inspections, both WSTDA
              WS-1 and ASME B30.9 require that a written record of the
              inspections be maintained.
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid></Grid>
    </div>
  );
}
