import React from "react";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MessageIcon from "@mui/icons-material/Message";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";

const StyledContactDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const StyledContainer = styled(Container)({
  flexGrow: '1',
});

const StyledPaper = styled(Paper)({
  padding: '5px',
  marginBottom: '10px',
});

const StyledPageTitle = styled(Typography)({
  padding: '15px',
  fontSize: '50px',
  fontWeight: '700',
  marginBottom: '20px',
  '@media (max-width:600px)': {
    padding: '20px',
  },
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'black',
});

const StyledPageContent = styled(Typography)({
  fontSize: '25px',
  '@media (max-width:600px)': {
    fontSize: '20px',
  },
});

const StyledMapImage = styled('img')({
  height: 'auto',
  maxWidth: '70px',
});

const Contact = () => {
  return (
    <StyledContactDiv id="contact" align="center">
      <StyledContainer maxWidth="lg">
        <StyledPageTitle align="center">
          Contact Us
        </StyledPageTitle>
        <div style={{ textAlign: "center" }}>
          <Grid container spacing={4} alignItems="center" justify="center">
            <Grid item md={6} lg={6}>
              <StyledPageContent component="span" align="center">
                <strong>Thor-Tex,</strong> a product of <br />
                Sea-Land Distributors, LLC
              </StyledPageContent>

              <StyledPageContent align="center">
                1000 Edwards Avenue, Suite C <br />
              </StyledPageContent>
              <StyledPageContent className={StyledPageContent} align="center">
                New Orleans, Louisiana 70123 USA
              </StyledPageContent>
              <StyledPageContent component="span" align="center">
                <strong>Hours:</strong> Monday-Friday 8 a.m. - 5 p.m. CT
              </StyledPageContent>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/Sea-Land+Distributors,+LLC./@29.955757,-90.193528,15z/data=!4m5!3m4!1s0x0:0xacc2bccf2da70d2d!8m2!3d29.955757!4d-90.193528"
                >
                  <StyledMapImage
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Google_Maps_icon.svg/1200px-Google_Maps_icon.svg.png"
                    alt=""
                  />
                </a>
              </div>
            </Grid>

            <Grid item md={6} lg={6}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="center"
                className={StyledPageContent}
                style={{ padding: "10px" }}
              >
                <Grid item xs={12} sm={6} md={5}>
                  <a href="tel:504-736-9477" className={StyledLink} style={{textDecoration: "none"}}>
                    <StyledPaper>
                      <CallIcon style={{ verticalAlign: "middle" }} />
                      <br />
                      <span style={{ fontSize: "18px" }}>504-736-9477</span>
                    </StyledPaper>
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <a href="mailto:info@thor-tex.com" className={StyledLink}>
                    <StyledPaper>
                      <MailOutlineIcon style={{ verticalAlign: "middle" }} />
                      <br />
                      <span style={{ fontSize: "18px" }}>
                        info@thor-tex.com
                      </span>
                    </StyledPaper>
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={10}>
                  <StyledLink to="/contact-form">
                    <StyledPaper>
                      <MessageIcon style={{ verticalAlign: "middle" }} />
                      <br />
                      <span style={{ fontSize: "18px" }}>
                        Send us a Message
                      </span>
                    </StyledPaper>
                  </StyledLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </StyledContainer>
    </StyledContactDiv>
  );
}

export default Contact;
