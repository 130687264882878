import React from 'react'
import JotformEmbed from 'react-jotform-embed';
import { Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './../App.css';

const useStyles = makeStyles(theme => ({
  pagetitle: {
    padding: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "30px",
    fontWeight: "700",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      padding: "20px",
    },
  },
}));

function Contactform() {
    const classes = useStyles();

    return (
      <div>
      
      <Typography align="center" className={classes.pagetitle}>
       CONTACT US
      </Typography>       
  
      <Grid container>
         <JotformEmbed src="https://form.jotform.com/200904991522959" />
      </Grid>
      <div style={{marginBottom: '50px'}}></div>            
      </div>
    )
}
export default Contactform