import React from "react";
import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import sld from './../assets/sld-logo.svg'
import Erigging from './../assets/erigging.png'

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    paddingBottom: "35px",
    fontSize: "50px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      padding: "20px",
    },
  },
  pagesubtitle: {
    paddingBottom: "40px",
  },
  img: {
    width: "50%",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
  },
}));

export default function Wheretobuy() {
  const classes = useStyles();
  return (
    <div
      id="wheretobuy"
      style={{ backgroundColor: "#d6d9dd", display: "flex" }}
    >
      <Container
        maxWidth="lg"

      >
        <Typography align="center" className={classes.pagetitle} style={{fontSize: "50px",
    fontWeight: "700",}}>
          Where to Buy
        </Typography>
        <Grid container spacing={3} justify="center" alignItems="center" style={{textAlign:'center'}}>
          <Grid item xs={12} md={12} lg={12}>
            <a
              href="https://www.e-rigging.com/Synthetic-Lifting-Slings_c_3879.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Erigging} className={classes.img} alt="" />
            </a>
          </Grid>
          {/* <Grid item  xs={12} lg={4}>
            <a
              href="https://www.amazon.com/s?k=Thor-Tex"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={wtb1} className={classes.img} alt="" />
            </a>
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={6}>
            <a
              href="https://www.sldrigging.com/contactform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sld} className={classes.img} alt="" />
            </a>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
}
