import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Topnav from '../components/Topnav'
import Scroll from './Scroll'


const useStyles = makeStyles(theme => ({
  root: {    
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },    
  },
  tree:{
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  },
  list : {
    width : 200,
  },  
  sideBarIcon : {
    color : "white",
    cursor : "pointer",
  },
  link:
    { 
      fontSize: '17px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: 'black',
      display:'flex',
      justifyContent: 'space-evenly',
      '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .6s ease-in-out',
     },   
    },
    reslink:
    {
        textDecoration: 'none',
        color: '#213C54',
    },
     reslink2:
    {
        textDecoration: 'none',
        color: '#213C54',
       fontSize:'15px'
    },
    
}));

function ElevateAppBar() {
  const classes = useStyles();
 
  return (    
    <React.Fragment>
      <Topnav/>
      <AppBar className={classes.root} position="sticky" 
      style={{ backgroundColor: '#00be00', height:'50px'}}>        
        <Toolbar  style={{minHeight: '54px'}}>
        <Typography  component={'span'} variant={'body2'}  align="center"> 
        <Scroll/>       
          </Typography>          
          <Typography  component={'span'} variant={'body2'} color="inherit" style={{flexGrow:1}}> 
          <Link to="/polyester-web" className={classes.link}>POLYESTER WEB SLINGS
          </Link></Typography>
          <Typography  component={'span'} variant={'body2'}  color="inherit" style={{flexGrow:1}}> 
          <Link to="/safety-bulletin" className={classes.link}>PWS SAFETY BULLETIN</Link></Typography>
          <Typography  component={'span'} variant={'body2'}  color="inherit" style={{flexGrow:1}}> 
          <Link to="/polyester-round" className={classes.link}>POLYESTER ROUND SLINGS</Link></Typography>  
          <Typography  component={'span'} variant={'body2'} color="inherit" style={{flexGrow:1}}> 
          <Link to="/fabrication" className={classes.link}>FABRICATION
          </Link></Typography> 
          <Typography  component={'span'} variant={'body2'} color="inherit" style={{flexGrow:1}}> 
          <Link to="/online-certs" className={classes.link}>ONLINE CERTS
          </Link></Typography> 
      </Toolbar>
      </AppBar>      
    </React.Fragment>
  );
};


export default ElevateAppBar;