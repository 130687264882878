export const partArr = [
  {
    label: "91210005 1 x 2, 1 ply, Unlined, Flat Eye",
    value: 91210005,
  },
  {
    label: "91210010 1 x 3, 1 ply, Unlined, Flat Eye",
    value: 91210010,
  },
  {
    label: "91210015 1 x 4, 1 ply, Unlined, Flat Eye",
    value: 91210015,
  },
  {
    label: "91210020 1 x 6, 1 ply, Unlined, Flat Eye",
    value: 91210020,
  },
  {
    label: "91210025 1 x 8, 1 ply, Unlined, Flat Eye",
    value: 91210025,
  },
  {
    label: "91210030 1 x 10, 1 ply, Unlined, Flat Eye",
    value: 91210030,
  },
  {
    label: "91211005 2 x 4, 1 ply, Unlined, Flat Eye",
    value: 91211005,
  },
  {
    label: "91211010 2 x 6, 1 ply, Unlined, Flat Eye",
    value: 91211010,
  },
  {
    label: "91211015 2 x 8, 1 ply, Unlined, Flat Eye",
    value: 91211015,
  },
  {
    label: "91211020 2 x 10, 1 ply, Unlined, Flat Eye",
    value: 91211020,
  },
  {
    label: "91211025 2 x 12, 1 ply, Unlined, Flat Eye",
    value: 91211025,
  },
  {
    label: "91211030 2 x 16, 1 ply, Unlined, Flat Eye",
    value: 91211030,
  },
  {
    label: "91211035 2 x 20, 1 ply, Unlined, Flat Eye",
    value: 91211035,
  },
  {
    label: "91212005 3 x 6, 1 ply, Unlined, Tapered Eye",
    value: 91212005,
  },
  {
    label: "91212010 3 x 8, 1 ply, Unlined, Tapered Eye",
    value: 91212010,
  },
  {
    label: "91212015 3 x 10, 1 ply, Unlined, Tapered Eye",
    value: 91212015,
  },
  {
    label: "91212020 3 x 12, 1 ply, Unlined, Tapered Eye",
    value: 91212020,
  },
  {
    label: "91212025 3 x 20, 1 ply, Unlined, Tapered Eye",
    value: 91212025,
  },
  {
    label: "91213005 4 x 8, 1 ply, Unlined, Tapered Eye",
    value: 91213005,
  },
  {
    label: "91213010 4 x 10, 1 ply, Unlined, Tapered Eye",
    value: 91213010,
  },
  {
    label: "91213015 4 x 15, 1 ply, Unlined, Tapered Eye",
    value: 91213015,
  },
  {
    label: "91213020 4 x 20, 1 ply, Unlined, Tapered Eye",
    value: 91213020,
  },
  {
    label: "91214005 1 x 2, 1 ply, Lined, Flat Eye",
    value: 91214005,
  },
  {
    label: "91214010 1 x 3, 1 ply, Lined, Flat Eye",
    value: 91214010,
  },
  {
    label: "91214015 1 x 4, 1 ply, Lined, Flat Eye",
    value: 91214015,
  },
  {
    label: "91214020 1 x 6, 1 ply, Lined, Flat Eye",
    value: 91214020,
  },
  {
    label: "91214025 1 x 8, 1 ply, Lined, Flat Eye",
    value: 91214025,
  },
  {
    label: "91214030 1 x 10, 1 ply, Lined, Flat Eye",
    value: 91214030,
  },
  {
    label: "91215005 2 x 4, 1 ply, Lined, Flat Eye",
    value: 91215005,
  },
  {
    label: "91215010 2 x 6, 1 ply, Lined, Flat Eye",
    value: 91215010,
  },
  {
    label: "91215015 2 x 8, 1 ply, Lined, Flat Eye",
    value: 91215015,
  },
  {
    label: "91215020 2 x 10, 1 ply, Lined, Flat Eye",
    value: 91215020,
  },
  {
    label: "91215025 2 x 12, 1 ply, Lined, Flat Eye",
    value: 91215025,
  },
  {
    label: "91215030 2 x 16, 1 ply, Lined, Flat Eye",
    value: 91215030,
  },
  {
    label: "91215035 2 x 20, 1 ply, Lined, Flat Eye",
    value: 91215035,
  },
  {
    label: "91216005 3 x 6, 1 ply, Lined, Tapered Eye",
    value: 91216005,
  },
  {
    label: "91216010 3 x 8, 1 ply, Lined, Tapered Eye",
    value: 91216010,
  },
  {
    label: "91216015 3 x 10, 1 ply, Lined, Tapered Eye",
    value: 91216015,
  },
  {
    label: "91216020 3 x 12, 1 ply, Lined, Tapered Eye",
    value: 91216020,
  },
  {
    label: "91216025 3 x 20, 1 ply, Lined, Tapered Eye",
    value: 91216025,
  },
  {
    label: "91217005 4 x 8, 1 ply, Lined, Tapered Eye",
    value: 91217005,
  },
  {
    label: "91217010 4 x 10, 1 ply, Lined, Tapered Eye",
    value: 91217010,
  },
  {
    label: "91217015 4 x 15, 1 ply, Lined, Tapered Eye",
    value: 91217015,
  },
  {
    label: "91217020 4 x 20, 1 ply, Lined, Tapered Eye",
    value: 91217020,
  },
  {
    label: "91218005 1 x 2, 2 ply, Unlined, Flat Eye",
    value: 91218005,
  },
  {
    label: "91218010 1 x 3, 2 ply, Unlined, Flat Eye",
    value: 91218010,
  },
  {
    label: "91218015 1 x 4, 2 ply, Unlined, Flat Eye",
    value: 91218015,
  },
  {
    label: "91218020 1 x 6, 2 ply, Unlined, Flat Eye",
    value: 91218020,
  },
  {
    label: "91218025 1 x 8, 2 ply, Unlined, Flat Eye",
    value: 91218025,
  },
  {
    label: "91218030 1 x 10, 2 ply, Unlined, Flat Eye",
    value: 91218030,
  },
  {
    label: "91219005 2 x 4, 2 ply, Unlined, Flat Eye",
    value: 91219005,
  },
  {
    label: "91219010 2 x 6, 2 ply, Unlined, Flat Eye",
    value: 91219010,
  },
  {
    label: "91219015 2 x 8, 2 ply, Unlined, Flat Eye",
    value: 91219015,
  },
  {
    label: "91219020 2 x 10, 2 ply, Unlined, Flat Eye",
    value: 91219020,
  },
  {
    label: "91219025 2 x 12, 2 ply, Unlined, Flat Eye",
    value: 91219025,
  },
  {
    label: "91219030 2 x 16, 2 ply, Unlined, Flat Eye",
    value: 91219030,
  },
  {
    label: "91219035 2 x 20, 2 ply, Unlined, Flat Eye",
    value: 91219035,
  },
  {
    label: "91220005 3 x 6, 2 ply, Unlined, Tapered Eye",
    value: 91220005,
  },
  {
    label: "91220010 3 x 8, 2 ply, Unlined, Tapered Eye",
    value: 91220010,
  },
  {
    label: "91220015 3 x 10, 2 ply, Unlined, Tapered Eye",
    value: 91220015,
  },
  {
    label: "91220020 3 x 12, 2 ply, Unlined, Tapered Eye",
    value: 91220020,
  },
  {
    label: "91220025 3 x 20, 2 ply, Unlined, Tapered Eye",
    value: 91220025,
  },
  {
    label: "91221005 4 x 8, 2 ply, Unlined, Tapered Eye",
    value: 91221005,
  },
  {
    label: "91221010 4 x 10, 2 ply, Unlined, Tapered Eye",
    value: 91221010,
  },
  {
    label: "91221015 4 x 15, 2 ply, Unlined, Tapered Eye",
    value: 91221015,
  },
  {
    label: "91221020 4 x 20, 2 ply, Unlined, Tapered Eye",
    value: 91221020,
  },
  {
    label: "91222005 1 x 2, 2 ply, Lined, Flat Eye",
    value: 91222005,
  },
  {
    label: "91222010 1 x 3, 2 ply, Lined, Flat Eye",
    value: 91222010,
  },
  {
    label: "91222015 1 x 4, 2 ply, Lined, Flat Eye",
    value: 91222015,
  },
  {
    label: "91222020 1 x 6, 2 ply, Lined, Flat Eye",
    value: 91222020,
  },
  {
    label: "91222025 1 x 8, 2 ply, Lined, Flat Eye",
    value: 91222025,
  },
  {
    label: "91222030 1 x 10, 2 ply, Lined, Flat Eye",
    value: 91222030,
  },
  {
    label: "91223005 2 x 4, 2 ply, Lined, Flat Eye",
    value: 91223005,
  },
  {
    label: "91223010 2 x 6, 2 ply, Lined, Flat Eye",
    value: 91223010,
  },
  {
    label: "91223015 2 x 8, 2 ply, Lined, Flat Eye",
    value: 91223015,
  },
  {
    label: "91223020 2 x 10, 2 ply, Lined, Flat Eye",
    value: 91223020,
  },
  {
    label: "91223025 2 x 12, 2 ply, Lined, Flat Eye",
    value: 91223025,
  },
  {
    label: "91223030 2 x 16, 2 ply, Lined, Flat Eye",
    value: 91223030,
  },
  {
    label: "91223035 2 x 20, 2 ply, Lined, Flat Eye",
    value: 91223035,
  },
  {
    label: "91224005 3 x 6, 2 ply, Lined, Tapered Eye",
    value: 91224005,
  },
  {
    label: "91224010 3 x 8, 2 ply, Lined, Tapered Eye",
    value: 91224010,
  },
  {
    label: "91224015 3 x 10, 2 ply, Lined, Tapered Eye",
    value: 91224015,
  },
  {
    label: "91224020 3 x 12, 2 ply, Lined, Tapered Eye",
    value: 91224020,
  },
  {
    label: "91224025 3 x 20, 2 ply, Lined, Tapered Eye",
    value: 91224025,
  },
  {
    label: "91225005 4 x 8, 2 ply, Lined, Tapered Eye",
    value: 91225005,
  },
  {
    label: "91225010 4 x 10, 2 ply, Lined, Tapered Eye",
    value: 91225010,
  },
  {
    label: "91225015 4 x 15, 2 ply, Lined, Tapered Eye",
    value: 91225015,
  },
  {
    label: "91225020 4 x 20, 2 ply, Lined, Tapered Eye",
    value: 91225020,
  },
  {
    label: "91226005 1 x 2, 1 ply, Unlined, Twisted Eye",
    value: 91226005,
  },
  {
    label: "91226010 1 x 3, 1 ply, Unlined, Twisted Eye",
    value: 91226010,
  },
  {
    label: "91226015 1 x 4, 1 ply, Unlined, Twisted Eye",
    value: 91226015,
  },
  {
    label: "91226020 1 x 6, 1 ply, Unlined, Twisted Eye",
    value: 91226020,
  },
  {
    label: "91226025 1 x 8, 1 ply, Unlined, Twisted Eye",
    value: 91226025,
  },
  {
    label: "91226030 1 x 10, 1 ply, Unlined, Twisted Eye",
    value: 91226030,
  },
  {
    label: "91227005 2 x 4, 1 ply, Unlined, Twisted Eye",
    value: 91227005,
  },
  {
    label: "91227010 2 x 6, 1 ply, Unlined, Twisted Eye",
    value: 91227010,
  },
  {
    label: "91227015 2 x 8, 1 ply, Unlined, Twisted Eye",
    value: 91227015,
  },
  {
    label: "91227020 2 x 10, 1 ply, Unlined, Twisted Eye",
    value: 91227020,
  },
  {
    label: "91227025 2 x 12, 1 ply, Unlined, Twisted Eye",
    value: 91227025,
  },
  {
    label: "91227030 2 x 16, 1 ply, Unlined, Twisted Eye",
    value: 91227030,
  },
  {
    label: "91227035 2 x 20, 1 ply, Unlined, Twisted Eye",
    value: 91227035,
  },
  {
    label: "91228005 3 x 6, 1 ply, Unlined, Twisted tapered Eye",
    value: 91228005,
  },
  {
    label: "91228010 3 x 8, 1 ply, Unlined, Twisted tapered Eye",
    value: 91228010,
  },
  {
    label: "91228015 3 x 10, 1 ply, Unlined, Twisted tapered Eye",
    value: 91228015,
  },
  {
    label: "91228020 3 x 12, 1 ply, Unlined, Twisted tapered Eye",
    value: 91228020,
  },
  {
    label: "91228025 3 x 20, 1 ply, Unlined, Twisted tapered Eye",
    value: 91228025,
  },
  {
    label: "91229005 4 x 8, 1 ply, Unlined, Twisted tapered Eye",
    value: 91229005,
  },
  {
    label: "91229010 4 x 10, 1 ply, Unlined, Twisted tapered Eye",
    value: 91229010,
  },
  {
    label: "91229015 4 x 15, 1 ply, Unlined, Twisted tapered Eye",
    value: 91229015,
  },
  {
    label: "91229020 4 x 20, 1 ply, Unlined, Twisted tapered Eye",
    value: 91229020,
  },
  {
    label: "91230005 1 x 2, 2 ply, Unlined, Twisted Eye",
    value: 91230005,
  },
  {
    label: "91230010 1 x 3, 2 ply, Unlined, Twisted Eye",
    value: 91230010,
  },
  {
    label: "91230015 1 x 4, 2 ply, Unlined, Twisted Eye",
    value: 91230015,
  },
  {
    label: "91230020 1 x 6, 2 ply, Unlined, Twisted Eye",
    value: 91230020,
  },
  {
    label: "91230025 1 x 8, 2 ply, Unlined, Twisted Eye",
    value: 91230025,
  },
  {
    label: "91230030 1 x 10, 2 ply, Unlined, Twisted Eye",
    value: 91230030,
  },
  {
    label: "91231005 2 x 4, 2 ply, Unlined, Twisted Eye",
    value: 91231005,
  },
  {
    label: "91231010 91231010 2 x 6, 2 ply, Unlined, Twisted Eye",
    value: 91231010,
  },
  {
    label: "91231015 2 x 8, 2 ply, Unlined, Twisted Eye",
    value: 91231015,
  },
  {
    label: "91231020 2 x 10, 2 ply, Unlined, Twisted Eye",
    value: 91231020,
  },
  {
    label: "91231025 2 x 12, 2 ply, Unlined, Twisted Eye",
    value: 91231025,
  },
  {
    label: "91231030 2 x 16, 2 ply, Unlined, Twisted Eye",
    value: 91231030,
  },
  {
    label: "91231035 2 x 20, 2 ply, Unlined, Twisted Eye",
    value: 91231035,
  },
  {
    label: "91232005 3 x 6, 2 ply, Unlined, Twisted tapered Eye",
    value: 91232005,
  },
  {
    label: "91232010 3 x 8, 2 ply, Unlined, Twisted tapered Eye",
    value: 91232010,
  },
  {
    label: "91232015 3 x 10, 2 ply, Unlined, Twisted tapered Eye",
    value: 91232015,
  },
  {
    label: "91232020 3 x 12, 2 ply, Unlined, Twisted tapered Eye",
    value: 91232020,
  },
  {
    label: "91232025 3 x 20, 2 ply, Unlined, Twisted tapered Eye",
    value: 91232025,
  },
  {
    label: "91233005 4 x 8, 2 ply, Unlined, Twisted tapered Eye",
    value: 91233005,
  },
  {
    label: "91233010 4 x 10, 2 ply, Unlined, Twisted tapered Eye",
    value: 91233010,
  },
  {
    label: "91233015 4 x 15, 2 ply, Unlined, Twisted tapered Eye",
    value: 91233015,
  },
  {
    label: "91233020 4 x 20, 2 ply, Unlined, Twisted tapered Eye",
    value: 91233020,
  },
];
