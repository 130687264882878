import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Link} from 'react-router-dom'
import { AppBar, Toolbar, Grid, Box,  } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/invlogo.png'

const useStyles = makeStyles({
  list: {
    width: 230,
  },
  fullList: {
    width: 'auto',
  },
  color: {
    backgroundColor: '#00be00',
},
reslogo: {
    height: '30px',
    paddingTop: '10px'   
},
reslink: {
    textDecoration: 'none',
    color: '#00be00',
    fontWeight: 'bold',
    fontSize: '17px',
    fontFamily: 'Lato',
},
reslink2: {
    textDecoration: 'none',
    color: '#00be00',
    fontSize: '16px',
    fontFamily: 'Lato',
},

});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({    
    left: false,    
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <div className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>                           
              <ListItem key = {1} button divider>
             <Link to="/polyester-web" className={classes.reslink}>Polyester Web Slings</Link></ListItem>
               <ListItem key = {2} button divider>
               <Link to="/safety-bulletin" className={classes.reslink}>PWS Safety Bulletin</Link></ListItem>
               <ListItem key = {3} button divider>
               <Link to="/polyester-round" className={classes.reslink}>Polyester Round Slings</Link></ListItem>  
               <ListItem key = {10} button divider>
               <Link to="/fabrication" className={classes.reslink}>Fabrication Standards</Link></ListItem>  
               <ListItem key = {4} button divider>
              <Link to="/online-certs" className={classes.reslink}>Online Certificates</Link></ListItem>            
               <ListItem key = {5} button divider></ListItem>
               <ListItem key = {6} button divider> 
               <Link to="/" className={classes.reslink2}>Home</Link> </ListItem>
               <ListItem key = {7} button divider>
               <a href="/#contact" className={classes.reslink2}>Contact</a></ListItem> 
               <ListItem key = {8} button divider>
               <a href="/#about" className={classes.reslink2}>About</a> </ListItem>
               <ListItem key = {9} button divider>
               <a href="/#wheretobuy" className={classes.reslink2}>Where to Buy</a> </ListItem>
              
      </List>
    </div>
  );

  return (
    <div>             
       <Box	display={{ xs: 'block', md: 'block', sm: 'block', lg: 'none' }} >
      {['left'].map(anchor => (
        <React.Fragment key={anchor}>
            <AppBar  style={{backgroundColor: "#00BE00"}} >        
          <Toolbar >
            <Grid container direction = "row" justify = "space-between" style={{justifyContent: "space-between"}}  >            
              <MenuIcon style={{fontSize:'30px', color: 'black', paddingTop:'9px'}} onClick={toggleDrawer(anchor, true)}>{anchor}</MenuIcon>          
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
          <Link to="/" style={{justifyContent: "flex-end"}} > 
          <img src={logo} className={classes.reslogo}  alt=""/></Link>
          </Grid>
          </Toolbar>
        </AppBar>
        </React.Fragment>
      ))}
      </Box>
    </div>
  );
}