import React from "react";
import { styled } from "@mui/system";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Grid from "@mui/material/Grid";
import flatEye from "./../assets/FLAT_EYE.png"; 
import linedEye from "./../assets/LINED_EYE.png"; 
import taperedEye from "./../assets/TAPERED_EYE.png"; 

function EventItem(props) {
  const { classes } = props;

  return (
    <div style={{marginTop: "15px"}}>
      <Typography style={{fontSize: "45px", textAlign: 'center', marginBottom: '15px', cursor: "pointer"}}>Eye Types</Typography>
      <Grid container justify="center" spacing={4} >
        <Grid item>
          <Card style={{ maxWidth: 240, cursor: 'pointer' }}>
            <CardActionArea style={{cursor: 'pointer'}}>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <CardMedia
                  style={{
                    width: "auto",
                    maxHeight: "185px",
                    cursor: "pointer"
                  }}
                  component="img"
                  image={flatEye}
                  title="Flat Eye"
                />
              </div>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Flat Eye
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Polyester Web Lifting Sling, Flat Eyes
          </Typography>
              </CardContent>
            </CardActionArea>
            {/* <CardActions>
              <Button size="small" color="primary">
                <Link href="https://www.e-rigging.com/thor-tex-usa-1-ply-1-4-eye-eye-sling-flat-eyes" >Shop Now</Link>
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ maxWidth: 240 }}>
            <CardActionArea>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center"
                }}
              >
                <CardMedia
                  style={{
                    width: "auto",
                    maxHeight: "185px"
                  }}
                  component="img"
                  image={taperedEye}
                  title="Tapered Eye"
                />
              </div>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Tapered Eye
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Polyester Web Lifting Sling, Tapered Eyes
          </Typography>
              </CardContent>
            </CardActionArea>
            {/* <CardActions>
              <Button size="small" color="primary">
                <Link href="https://www.e-rigging.com/thor-tex-usa-2-ply-4-15-eye-eye-sling-tapered-eyes" >Shop Now</Link>
              </Button>
            </CardActions> */}
          </Card>
        </Grid>

        <Grid item>
          <Card style={{ maxWidth: 240 }}>
            <CardActionArea>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center"
                }}
              >
                <CardMedia
                  style={{
                    width: "auto",
                    maxHeight: "185px"
                  }}
                  component="img"
                  image={linedEye}
                  title="Lined Eye"
                />
              </div>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Lined Eye
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Polyester Web Lifting Sling, Cordura Lined Flat Eyes
          </Typography>
              </CardContent>
            </CardActionArea>
            {/* <CardActions>
              <Button size="small" color="primary">
                <Link href="https://www.e-rigging.com/thor-tex-usa-1-ply-1-4-eye-eye-sling-lined-flat-eyes" >Shop Now</Link>
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
        {/* <Grid item>
          <Card style={{ maxWidth: 220 }}>
            <CardActionArea>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center"
                }}
              >
                <CardMedia
                  style={{
                    width: "auto",
                    maxHeight: "165px"
                  }}
                  component="img"
                  image={flatEye}
                  title="Twisted Eye"
                />
              </div>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Twisted Eye
                </Typography>

              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Shop Now
              </Button>
            </CardActions>
          </Card>
        </Grid> */}
      </Grid>
    </div>
  );
}

// EventItem.propTypes = {
//     classes: PropTypes.object.isRequired,
//   };
export default styled(EventItem)({});
