import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  bultitle: {
    backgroundColor: "#00be00",

    fontSize: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  pagecontent: {},
  pagesubtitle: {
    fontSize: "22px",
  },
}));

export default function Training() {
  const classes = useStyles();
  return (
    <div id="SLING STORAGE">
      <br />
      <br />
      <br />
      <Accordion
        defaultExpanded={true}
        style={{ backgroundColor: "#00be00", margin: "0px", padding: "0px" }}
      >
        <AccordionSummary
          style={{ height: "15px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={'span'}
>
            <ol start="6" className={classes.bultitle}>
              <li>SLING STORAGE</li>
            </ol>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
          <Grid>
            <Typography component={'span'}
 className={classes.pagecontent}>
              Knowledge is acquired through training. Sling users must be
              knowledgeable in the safe and proper use of polyester web slings
              to prevent injury and death to you and others. In addition to the
              information found in this bulletin, polyester web sling users
              should also read and understand the standards and guidance
              provided in:{" "}
            </Typography>
            <Typography component={'span'}
 className={classes.pagecontent}>
              <br />
              <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
                <li>
                  ASME B30.9-5 Synthetic Webbing Slings: Selection, Use &
                  Maintenance
                </li>
                <li>OSHA 29 CFR 1910.184: Slings</li>
                <li>OSHA Guidance On Safe Sling Use</li>
              </ul>
            </Typography>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight:'1.5'
              }}
            >
              <li>
                Polyester web slings should always be stored in dry, dark and
                cool places when not being used.{" "}
              </li>
              <li>
                Polyester web slings should always be stored away from exposure
                to chemicals, high heat sources, metal turnings from machining,
                grinding dust, splinters, etc..
              </li>
              <li>
                Always be sure polyester web slings are stored in place free
                from dirt, oils, grime and other such materials.
              </li>
              <li>
                Clean only with mild soap and water. If washed, be sure
                polyester web sling is 100% dry before being stored. Never
                machine wash as it will result in loss in strength.
              </li>
            </ul>
            <br />
            <Grid align="center"></Grid>
            <Typography component={'span'}
 className={classes.pagecontent}>
              There are many factors that should be considered when using web
              slings to lift loads. Some of these factors include:
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "50px",
                    lineHeight:'1.5'
                  }}
                >
                  <li> Wind </li>
                  <li>Visibility </li>
                  <li>Air temperatures</li>
                  <li>Center of Gravity </li>
                  <li>Clear Load Path </li>
                  <li>Sling Selection </li>
                  <li>Lift Point </li>
                  <li>Signals</li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "50px",
                    lineHeight:'1.5'
                  }}
                >
                  <li> Weather </li>
                  <li>Ground Stability </li>
                  <li> Weight</li>
                  <li>Dimensions </li>
                  <li>Structural Stability</li>
                  <li>Load Control</li>
                  <li>Sling Capacity </li>
                  <li>Planning</li>
                </ul>
              </Grid>
            </Grid>
            <br />
            <Typography component={'span'}
 className={classes.pagecontent}>
              The information in this bulletin does not contain ALL of the
              information required to use web slings safely. Other recommended
              sources of information include:
            </Typography>
            <br />
            <Typography component={'span'}
 className={classes.pagecontent}>
              <ul style={{ listStyleType: "disc", paddingLeft: "50px", lineHeight:'1.5' }}>
                <li>OSHA 29CFR 1910.184</li>
                <li>
                  Web Sling Tie Down Association (WSTDA) “WS-1” Recommended
                  Standard for Synthetic Web Slings
                </li>
                <li>ASME B30.9 Sling Standard</li>
              </ul>
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid></Grid>
    </div>
  );
}
