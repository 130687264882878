import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  bultitle: {
    backgroundColor: "#00be00",
    fontSize: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  pagecontent: {},
  pagesubtitle: {
    fontSize: "22px",
  },
}));

export default function Training() {
  const classes = useStyles();
  return (
    <div id="SLING PROTECTION">
      <br />
      <br />
      <br />
      <Accordion
        defaultExpanded={true}
        style={{ backgroundColor: "#00be00", margin: "0px", padding: "0px" }}
      >
        <AccordionSummary
          style={{ height: "15px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={'span'}
>
            <ol start="3" className={classes.bultitle}>
              <li>SLING PROTECTION</li>
            </ol>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
          <Grid>
            <Typography component={'span'}
 className={classes.pagecontent}>
              To protect polyester web slings from both environmental and
              physical damage, avoid the following:
            </Typography>
            <br />
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "30px",
                lineHeight:'1.5'
              }}
            >
              <li> Continuous or excessive exposure to sunlight.</li>
              <li>Contact with dirt or gritty type substances.</li>
              <li>Dragging slings on the floor, ground or rough surfaces.</li>
              <li>Jerking or pulling slings out from under loads.</li>
              <li>Twisting or tying knots in slings.</li>
              <li>Allowing slings to contact acids or alkalis.</li>
              <li>
                Exposing slings to temperatures above 194°F or below –40°F.
              </li>
              <li>Allowing weld spatter to land on slings.</li>
              <li>
                Allowing sling to sit on the tip of a hook instead of the base
                of the hook’s bowl.
              </li>
              <li>
                Using rigging hardware such as hooks or shackles with rough
                edges.
              </li>
              <li>
                Running or driving over slings with vehicles or mobile
                equipment.
              </li>
            </ul>
            <br />
            <Typography component={'span'}
 className={classes.pagecontent}>
              To help prevent damage from sharp edges, corners and other
              abrasive surfaces, protective devices such as wear pads, sleeves,
              corner protectors and body wraps should be used. It may also be
              necessary to test samples to ensure protection method chosen is
              effective prior to lifting actual load to be lifted.
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>
     
    </div>
  );
}
