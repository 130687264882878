import React from 'react';
import Typography from '@mui/material/Typography';
import logo from '../assets/logo.svg'
import {Link} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {Box, Grid} from '@mui/material';

const useStyles = makeStyles({
  padding : {
    paddingRight : 30,
    cursor : "pointer",
  },
reslogo: {
    height: '70px',
    margin: '10px',   
},
logo: {
    height: '80px',
    margin: '12px',
    marginLeft: '10px',
},
toplink: { 
  fontSize: '22px',
  textDecoration: 'none',
  color: 'black',  
  margin: 'auto',
  padding:'10px',
'&:hover': {
  background: "lightgrey",
},
},
});

function Test2 () { 
  const classes = useStyles();

  return (
    <React.Fragment>   
         <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>   
      <Grid  container  direction="row"  justify="space-around"  alignItems="center" style={{backgroundColor: '#e4e4e4'}}>
      <Grid container item lg={5} md={5}>
      <Link to="/">
             <img src={logo} className={classes.logo} alt="Thor-tex logo"/></Link>
      </Grid>
      <Grid container item lg={7} md={7} style={{justifyContent: "flex-end"}}>                 
          <Typography  className = {classes.padding}>
           <a href="/#wheretobuy" className={classes.toplink}>Where to Buy</a></Typography>
          <Typography  className = {classes.padding}> 
          <a href="/#about" className={classes.toplink}>About</a></Typography>
          <Typography  className = {classes.padding}> 
          <a href="/#contact" className={classes.toplink}>Contact</a></Typography>
          <Typography  className = {classes.padding}>
          <Link to="/" className={classes.toplink}>Home</Link></Typography>
      </Grid>
      </Grid>      
      </Box>  
      {/* mobile  */}
      <Box	sx={{display:{ xs: 'none', sm: 'none' } }}> 
      <div align="center" style={{marginTop: '55px'}}>               
      </div>        
      </Box>      
    </React.Fragment>
  );
};

export default Test2;