import React from "react";
import { Grid, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    padding: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "30px",
    fontWeight: "700",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      padding: "20px",
    },
  },
  pagesubtitle: {
    padding: "100px",
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
      padding: "20px",
    },
  },
}));

export default function Boatlift() {
  const classes = useStyles();
  return (
    <div className="animate__animated animate__fadeIn">
      <Typography align="center" className={classes.pagetitle}>
        BOAT LIFT SLINGS
      </Typography>
      <Container maxWidth="md" style={{ minHeight: "48vh" }}>
        <Grid container justify="center" alignItems="center">
          {/* <img src={boatsling} alt="" style={{height: '250px', marginBottom:'20px'}}/> */}
        </Grid>
        <Typography align="center" className={classes.pagesubtitle}>
          Coming Soon
        </Typography>
      </Container>
    </div>
  );
}
