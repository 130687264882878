import React from 'react';
import img from './../../assets/cert/cert.jpg'
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  img: {
    marginTop:'10px',
    height:'150px',
    [theme.breakpoints.down("sm")]: {
      height:'100px',
    },
  },
   
}));
export default function SpacingGrid() {
  const classes = useStyles();
  return (
    <div style={{textAlign: 'center'}}>     
      <img src={img}  alt="Thor-Tex" className={classes.img}/>
      </div>
      
  )
  
}