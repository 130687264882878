import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import WarningIcon from "@mui/icons-material/Warning";
import Img15 from "./../../assets/img/15.png";
import Img16 from "./../../assets/img/16.png";
import Img14 from "./../../assets/img/14.png";

const useStyles = makeStyles((theme) => ({
  bultitle: {
    backgroundColor: "#00be00",

    fontSize: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  pagecontent: {},
  pagesubtitle: {
    fontSize: "22px",
  },
  responsive: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
  },
  pagecaption: {},
  theader: {
    fontWeight: "bold",

    fontSize: "18px",
    textAlign: "center",
  },
  tcell: {
    textAlign: "center",
    padding: "5px",
  },
}));

export default function Training() {
  const classes = useStyles();
  return (
    <div id="NEVER EXCEED RATED CAPACITY">
      <br />
      <br />
      <br />
      <Accordion
        defaultExpanded={true}
        style={{ backgroundColor: "#00be00", margin: "0px", padding: "0px" }}
      >
        <AccordionSummary
          style={{ height: "15px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={'span'}
>
            <ol start="4" className={classes.bultitle}>
              <li>NEVER EXCEED RATED CAPACITY</li>
            </ol>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
          <Grid>
            <Typography component={'span'}
 className={classes.pagecontent}>
              To avoid exceeding the rated capacity of a polyester web sling, a
              trained and qualified person must first know the weight of the
              load to be lifted and its center of gravity to determine if the
              rigging
            </Typography>
            <Typography component={'span'}
 className={classes.pagecontent}>
              Also, determine whether the sling will be used in a vertical
              hitch, choker hitch or vertical basket hitch. See table below for
              description and illustration.
            </Typography>
            <br />
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid
                item
                md={5}
                xs={12}
                style={{ flexBasis: "auto", textAlign: "center" }}
              >
                <img src={Img15} alt="img" style={{ height: "350px" }} />
              </Grid>
              <Grid item md={7} xs={12}>
                <Typography component={'span'}
 className={classes.pagecontent}>
                  It is also very important that a qualified person determine
                  the angle that the slings will be placed in for the intended
                  lift. This may even be a challenge for a qualified person. But
                  for someone unqualified and unaware of the effects that sling
                  angles can have on the rated capacity of the slings, the lack
                  of this knowledge or inability to apply the correct sling
                  reduction factors can lead to injury or death.
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ margin: "40px" }}>
              <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                style={{ backgroundColor: "#fa8b1c", border: "3px solid grey" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  style={{ textAlign: "center" }}
                >
                  <WarningIcon style={{ fontSize: "150px" }} />
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <Typography component={'span'}
 className={classes.pagesubtitle} align="center">
                    WARNING: <i>ANGLES MATTER!</i>
                  </Typography>
                  For Vertical & Basket hitches, sling angles less than 90
                  degreesreduce the effective rated capacity of the sling. See
                  table below.
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <TableContainer
                  component={Paper}
                  style={{
                    marginBottom: "15px",
                    marginTop: "10px",
                    maxWidth: "90%",
                  }}
                >
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                    style={{ padding: "5px" }}
                  >
                    <TableHead style={{ backgroundColor: "#00be00" }}>
                      <TableRow>
                        <TableCell className={classes.theader}>Angle</TableCell>
                        <TableCell className={classes.theader}>
                          Sling Reduction Factor
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.tcell}>90° </TableCell>
                        <TableCell className={classes.tcell}>1.000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 85° </TableCell>
                        <TableCell className={classes.tcell}>0.996</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 80° </TableCell>
                        <TableCell className={classes.tcell}>0.985</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 75° </TableCell>
                        <TableCell className={classes.tcell}>0.966</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 70° </TableCell>
                        <TableCell className={classes.tcell}>0.940</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 65° </TableCell>
                        <TableCell className={classes.tcell}>0.906</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 60° </TableCell>
                        <TableCell className={classes.tcell}>0.866</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 55° </TableCell>
                        <TableCell className={classes.tcell}>0.819</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 50° </TableCell>
                        <TableCell className={classes.tcell}>0.766</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 45° </TableCell>
                        <TableCell className={classes.tcell}>0.707</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 40° </TableCell>
                        <TableCell className={classes.tcell}>0.643</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 35° </TableCell>
                        <TableCell className={classes.tcell}>0.574</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tcell}> 30° </TableCell>
                        <TableCell className={classes.tcell}>0.500</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={6} md={8} style={{ textAlign: "center" }}>
                <img src={Img14} alt="" style={{ height: "100px" }} />
                <Typography 
 className={classes.pagesubtitle}>
                  <u>Vertical & Basket Hitches</u>
                </Typography>
                <Typography component={'span'}
 className={classes.pagecaption}>
                  To calculate the effective capacity of the sling, determine
                  the angle of the sling and multiply the sling reduction factor
                  for the determined angle times the rated Vertical or Basket
                  capacity of the sling
                </Typography>

                <Grid container style={{ textAlign: "center" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{ paddingTop: "20px" }}
                  >
                    <TableContainer
                      component={Paper}
                      style={{ marginBottom: "15px", maxWidth: "90%" }}
                      align="center"
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                        style={{ padding: "5px" }}
                      >
                        <TableBody>
                        <TableRow style={{ backgroundColor: "#00be00" }}>
                          <TableCell className={classes.theader}>
                            Angle of Choke
                          </TableCell>
                          <TableCell colSpan={2} className={classes.theader}>
                            Sling Reduction Factor{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tcell}>
                            {" "}
                            = or >{" "}
                          </TableCell>
                          <TableCell className={classes.tcell}>
                            {" "}
                            {"<"}{" "}
                          </TableCell>
                          <TableCell className={classes.theader}> </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tcell}>120° </TableCell>
                          <TableCell className={classes.tcell}>180°</TableCell>
                          <TableCell className={classes.tcell}>1.00</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tcell}>105° </TableCell>
                          <TableCell className={classes.tcell}>120°</TableCell>
                          <TableCell className={classes.tcell}>0.82</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tcell}>90° </TableCell>
                          <TableCell className={classes.tcell}>105°</TableCell>
                          <TableCell className={classes.tcell}>0.71</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tcell}> 60° </TableCell>
                          <TableCell className={classes.tcell}>90°</TableCell>
                          <TableCell className={classes.tcell}>0.58</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tcell}> 0° </TableCell>
                          <TableCell className={classes.tcell}>60°</TableCell>
                          <TableCell className={classes.tcell}>0.50</TableCell>
                        </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <img
                      src={Img16}
                      alt=""
                      style={{ height: "250px", paddingTop: "20px" }}
                    />
                  </Grid>
                </Grid>
                <Typography component={'span'}
 className={classes.pagecaption}>
                  For Choker hitches, to calculate the effective capacity of the
                  sling, determine the angle of choke and multiply the sling
                  reduction factor for the determined angle of choke times the
                  rated Choker capacity of the sling.
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={6} md={4}></Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid></Grid>
    </div>
  );
}
