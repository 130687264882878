import './App.css';
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Polysterweb from './components/Polysterweb';
import Polyesteround from './components/Polyesterround';
import Home from './components/Home';
import Boatlift from './components/Boatlift';
import Privacy from './components/Privacy'
import Termsofuse from './components/Termsofuse'
import Onlinecerts from './components/Onlinecerts';
import Contactform from './components/Contactform'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from './components/Appbar'
import  Box   from '@mui/material/Box';
import Drawer from './components/Drawer'
import Footer from './components/Footer';
import AddCertificate from './components/Cert/AddCertificate'
import AllCerts from './components/Cert/AllCerts'
import Fabrication from './components/Fabrication'
import Bulletin from './components/Bulletin/Bulletin';
import useGaTracker from './useGaTracker'


// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

 function App() {
   

  return (
    <div>
      {/* <ScrollToTop />  */}
      <Navbar/>   
      <Box display={{ lg: 'block' }} ><Drawer/></Box> 
      <Box display={{ xs: 'none', sm: 'block', md: 'none' }}><br/><br/></Box>

      <Routes>
        
        <Route exact path="/" element={<Home/>}></Route>
        <Route path="/contact-form" element={<Contactform/>}></Route>
        <Route path="/privacy-policy" element={<Privacy/>}></Route>
        <Route path="/terms-of-use" element={<Termsofuse/>}></Route>
        <Route path="/online-certs" element={<Onlinecerts/>}></Route>
        <Route path="/polyester-web" element={<Polysterweb/>}></Route>
        <Route path="/polyester-round" element={<Polyesteround/>}></Route>
        <Route path="/boatlift" element={<Boatlift/>}></Route>
        <Route path="/operatorAddCertificate" element={<AddCertificate/>} />
        <Route path="/operatorAllCerts" element={<AllCerts/>} />
        <Route path="/fabrication" element={<Fabrication/>} />
        <Route path="/safety-bulletin" element={<Bulletin/>}></Route>
      </Routes>
      <Footer/>
    </div>
  );
}


export default App;
