import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  bultitle: {
    backgroundColor: "#00be00",

    fontSize: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  pagecontent: {},
  pagesubtitle: {
    fontSize: "22px",
  },
}));

export default function Training() {
  const classes = useStyles();
  return (
    <div id="STAY CLEAR OF LOAD">
      {" "}
      <br />
      <br />
      <br />
      <Accordion
        defaultExpanded={true}
        style={{ backgroundColor: "#00be00", margin: "0px", padding: "0px" }}
      >
        <AccordionSummary
          style={{ height: "15px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={'span'}
>
            <ol start="5" className={classes.bultitle}>
              <li>STAY CLEAR OF LOAD</li>
            </ol>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
          <Grid>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight:'1.5'
              }}
            >
              <li>
                Never stand in the area directly beneath or near the load being
                lifted.{" "}
              </li>
              <li>Never stand on load being lifted.</li>
              <li>
                Never allow any part of your body to be placed between the sling
                and the load being lifted or between the sling and the lifting
                hook or rigging fitting.
              </li>
              <li>
                Be sure all personnel in the area of the lift are alert to the
                potential for the sling to become snagged during the lift.
              </li>
              <li>
                Never use a web sling to pull on objects in a constrained or
                snagged condition
              </li>
            </ul>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid></Grid>
    </div>
  );
}
